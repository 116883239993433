import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppadminService } from '../../appadmin.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-doctor-transactions',
  templateUrl: './doctor-transactions.component.html',
  styleUrls: ['./doctor-transactions.component.css']
})
export class DoctorTransactionsComponent implements OnInit {
  isdoctorsTransactionListLoading: boolean = false;
  isSpecialityShown: boolean; // hidden by default
  dtOptions: DataTables.Settings = {};
  flg: boolean = false;
  doctorsTransactionList = [];
  snackbarOptions: any = {
    duration: 3000,
    verticalPosition: 'bottom'
  };
  Speciality() {
    this.isSpecialityShown = !this.isSpecialityShown;
  }
  status: string;
  IsmodelShow: boolean = false;

  constructor(private appadmin: AppadminService, private snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      searching:false,
      serverSide: true,
      ordering:false,
      ajax: (dataTablesParameters: any, callback) => {
        this.isdoctorsTransactionListLoading = true;
        let expectedPage = dataTablesParameters.start/dataTablesParameters.length;
        this.appadmin.getTransactionList('doctor', expectedPage+1,dataTablesParameters.length).subscribe((data: any) => {
          this.isdoctorsTransactionListLoading = false;
          this.doctorsTransactionList = data.response
          callback({
            recordsTotal: Number.parseInt(data.totalcount),
            recordsFiltered: Number.parseInt(data.totalcount),
            data: []
          });

        },
          error => {
            this.isdoctorsTransactionListLoading = false;
          });
      }

    } ;
    //this.getDoctrsTransactions();
  }
  
  getDoctrsTransactions() {
    this.isdoctorsTransactionListLoading = true;
    this.appadmin.getDoctorsTransactins().subscribe((data:any)=> {
      this.isdoctorsTransactionListLoading = false;
      if (data) {
        console.log('getTransactions', data);
        this.doctorsTransactionList = data.response
        this.flg = true;
      } else {
        this.snackBar.open(data.message, '', this.snackbarOptions);
      }
    },
      error => {
        console.log(error);
      });
  }

  onchecked(e, item) {
    console.log(e.target.checked)
    if(item.doctorstatus == 'paid'){
    const dialogRef = this.dialog.open(confirmationpopComponent,
      {
        width: '396px',
        data: item
      }).afterClosed().subscribe(res => { 
        if (res == true) {
          let index = this.doctorsTransactionList.findIndex(x=> x.consultationId == item.consultationId)
          this.doctorsTransactionList[index].doctorstatus = 'notpaid'
          e.target.checked = false;
        console.log('doctorsTransactionList', this.doctorsTransactionList)
      }
     if(res == undefined){
        let index = this.doctorsTransactionList.findIndex(x=> x.consultationId == item.consultationId)
        this.doctorsTransactionList[index].doctorstatus == 'paid'
        e.target.checked = true;
        console.log('doctorsTransactionList', this.doctorsTransactionList)
      }
  });

  }else{
    let body = {
      consultationId: item.consultationId,
      status: 'paid',
      reason :{ reason :'' },
      person : 'DOCTOR'
    }
    this.appadmin.TransactionStatus(body).subscribe((x:any)=>{
      console.log(x)
      if(x.responseCode==201){
        this.snackBar.open(x.message, '', this.snackbarOptions);
        let index = this.doctorsTransactionList.findIndex(x=> x.consultationId == item.consultationId)
        this.doctorsTransactionList[index].doctorstatus = 'paid'
        e.target.checked = true;
      console.log(this.doctorsTransactionList)
      }else{
        this.snackBar.open(x.message, '', this.snackbarOptions);
      }
    },error=>{
      console.log(error)
    })
  }
}

}

@Component({
  selector: ' confirmation-pop',
  templateUrl: './confirmation-pop.html',
})
export class confirmationpopComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<confirmationpopComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private snackBar: MatSnackBar, 
    private appadmin: AppadminService,
    private fb: FormBuilder,
    private router: Router){}

    snackbarOptions: any = {
      duration: 3000,
      verticalPosition: 'bottom'
      };
      reasonForm:FormGroup;

      initilizeReasonForm(){
        this.reasonForm = this.fb.group({
           reason: ['', [Validators.required]]
        })
      }

      get f() { 
        return this.reasonForm.controls; 
      }

      changeDctrTransStatus(){
        if(!this.reasonForm.valid){
          this.validateAllFormFields(this.reasonForm)
        }else{
          let body = {
            consultationId: this.data.consultationId,
            status: 'notpaid',
            reason : { reason : this.reasonForm.get('reason').value},
            person : 'DOCTOR'
          }
          this.appadmin.TransactionStatus(body).subscribe((x:any)=>{
            console.log(x)
            if(x.responseCode==201){
              this.dialogRef.close(true);
              this.snackBar.open(x.message, '', this.snackbarOptions);
            }
        
          },error=>{
            console.log(error)
          })
        }

      }

      validateAllFormFields(formGroup: any) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

  ngOnInit() {
    this.initilizeReasonForm();
    console.log(this.data)
  }

}
