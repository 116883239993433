import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { AuthAuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'; 

@Injectable({
  providedIn: 'root'
})
export class BasicAuthInterceptorService implements HttpInterceptor , CanActivate{

  constructor(
    private authenticationService: AuthAuthenticationService,
    private router: Router
  ) {}
  
//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     if (this.authenticationService.isUserLoggedIn() && req.url.indexOf('basicauth') === -1) {
//         const authReq = req.clone({
//             headers: new HttpHeaders({
//                 'X-Requested-With' : 'XMLHttpRequest',
//                 'Content-Type': 'application/json',
//                 'Authorization': `Basic ${window.btoa(this.authenticationService.userName + ":" + this.authenticationService.passWord)}`,
//               })
//         });
//         return next.handle(authReq);
//     } else {
//         return next.handle(req);
//     }
// }
  
canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  if (this.authenticationService.isUserLoggedIn()) { // determine if the uder is logged in from this method.
      return true;
  }
  this.router.navigate(['/landingPage']);
  return false;
}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    if (sessionStorage.getItem('authenticatedUser') && sessionStorage.getItem('basicauth')) {
      req = req.clone({
        setHeaders: {
          Authorization: sessionStorage.getItem('basicauth')
        }
      })
    }
    return next.handle(req);
  }
}
