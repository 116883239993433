import { Component, OnInit } from '@angular/core';
import { DoctorService } from '../../doctor.service';
import { UserService } from '../../user.service';
import {PageEvent} from '@angular/material';
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

  // displayData: any = []
  // transactions: any = []
  // public pagingStartIndex: number
  // public pagingEndIndex: number
  // public pageSize: number
  // public numberOfPages: number
  // public pageNum: number[]
  // public currentPage: number


  datasource = [];
  displayData: any ;
  resultLength:any;
  transactions: any = [];
  showBackBtn: boolean = false;
  public pagingStartIndex: number;
  public pagingEndIndex: number;
  public numberOfPages: number;
  public pageNum: number[];
  public currentPage: number;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  activePageDataChunk = [];
  // MatPaginator Output
  pageEvent: PageEvent;


  constructor(public doctorService: DoctorService, public service: UserService, private router: Router) {
    // this.pagingStartIndex = 0
    // this.displayData = []
    // this.pageNum = []
    // this.pageSize = 5
    // this.currentPage = 1
    this.router.events.subscribe((navChange) => {
      if(navChange instanceof NavigationEnd) {
        this.showBackBtn = (navChange.urlAfterRedirects !== "/doctor/dashboard");
      }
    });
   }

   isAppointmentListLoading = false;

  ngOnInit() {
    this.service.userDetails = JSON.parse(localStorage.getItem('userDetails'));
     window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(JSON.stringify({"backButtonDisabled":false,"change": true,"path":"Transaction component"}))
     this.isAppointmentListLoading = true;
     console.log("notifyReact")
    this.doctorService.getTransactions(this.service.userDetails.docId).subscribe((res: any)=>{
      this.isAppointmentListLoading = false;
        //this.transactions = res.response
        //this.displayPage(1)
        this.transactions = res.response;
        console.log(this.transactions);
        console.log("---------------");
        this.resultLength = res.response.length;
        this.datasource.push(res.response);
        console.log(this.datasource);

        this.activePageDataChunk = this.datasource[0].slice(0,10);
        console.log(this.activePageDataChunk);
    })
  }

  // public displayPage(pageNumber: number) {
  //   this.pagingStartIndex = this.pageSize * (pageNumber - 1)
  //   this.pagingEndIndex = Number.parseInt(this.pagingStartIndex.toString()) + Number.parseInt(this.pageSize.toString()) - 1
  //   this.displayData = []
  //   this.pageNum = []
  //   let mod: number = this.transactions.length % this.pageSize
  //   if (mod > 0) this.numberOfPages = Math.floor(this.transactions.length / this.pageSize) + 1
  //   else this.numberOfPages = (this.transactions.length / this.pageSize)

  //   for (let i = 0; i < this.numberOfPages; i++) {
  //     this.pageNum.push(i + 1)
  //   }

  //   //update the data to be displayed based on above changes
  //   for (let i = this.pagingStartIndex; i <= this.pagingEndIndex; i++) {
  //     if (this.transactions[i] != null) {
  //       this.displayData.push(this.transactions[i])
  //     }
  //   }

  //   //update current page
  //   this.currentPage = pageNumber
  // }
   setPageSizeOptions(setPageSizeOptionsInput: string) {
     this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
   }

  onPageChanged(e) {
    console.log(e);
    let firstCut = e.pageIndex * e.pageSize;
    let secondCut = firstCut + e.pageSize;
    this.activePageDataChunk = this.datasource[0].slice(firstCut, secondCut);
  }
  goBackToPreviousPage(): void {
    window.history.back();
  }
}
