import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DoctorService } from "../../doctor.service";
import { HealthworkerService } from "../../healthworker/healthworker.service";
import { UserService } from "../../user.service";
import { DatePipe } from '@angular/common';
import { NotifyhealthworkerService } from '../../notifyhealthworker.service';
import { v4 as uuidv4 } from 'uuid';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: "app-jitsi-video",
  templateUrl: "./jitsi-video.component.html",
  styleUrls: ["./jitsi-video.component.css"],
})
export class JitsiVideoComponent implements OnInit {
  api: any;
  callEnded = false;
  callEndConfirmation: any;
  consultationId:any;
  jitsiLink:any;
  uuId : any;
  roomName:any;

  constructor(
    public service: UserService,
    private doctorService: DoctorService,
    private healthworkerService: HealthworkerService,
    private router: Router,
    private datePipe: DatePipe,
    private notifyhealthworkerService:NotifyhealthworkerService
  ) {}

  ngOnInit() {

    if(this.uuId == null){
      this.uuId = uuidv4;
    }
    this.service.userDetails = JSON.parse(localStorage.getItem("userDetails"));

    this.service.consultation = JSON.parse(
      localStorage.getItem("consultation")
    );
    console.log(this.service.userDetails);
    console.log('hello',this.service.consultation);
    this.jitsiMeet();
    
    console.log('hello',this.service.consultation);

    window.addEventListener("message", (data: any) => {
      //console.log(data)
      this.callEndConfirmation = JSON.parse(data.data);
      if (
        this.service.userDetails.docId == null &&
        this.callEndConfirmation.exitValue &&
        this.callEndConfirmation.exitValue == true
      ) {
        this.api.dispose();
        this.router.navigate(["healthworker/appointmentList"]);
      }

      if (
        this.service.userDetails.docId != null &&
        this.callEndConfirmation.exitValue &&
        this.callEndConfirmation.exitValue == true
      ) {
        this.api.dispose();
        this.completeAppointment();
        this.router.navigate(["doctor/dashboard"]);
      }
    });
  }

  jitsiMeet() {
    this.consultationId = this.service.consultation.consultationId;
    this.roomName = this.service.consultation.callId;
    // console.log("constid");
    console.log(this.consultationId);
    let uniqueId = this.consultationId;
    const doctorName = this.service.consultation.docName;
    const patientName = this.service.consultation.patName;
    const hwName = this.service.consultation.hwName;    
    const domain = "vc.vara.care";

    const options = { 
      roomName: this.roomName,
      // roomName : this.uuId,
      parentNode: document.querySelector("#meet"),
      configOverwrite: {
        disableDeepLinking: true,
        defaultLanguage: "en",
        subject: ' '
      },
      userInfo: {                
        displayName: doctorName, 
      },
    };
    
    // if (this.service.userDetails.docId == null){
    //   options.userInfo.displayName = hwName;
    //   // this.jitsiLink=this.service.consultation.body
    //   this.jitsiLink = domain + "/" + options.roomName;
    //   // var uid = this.jitsiLink.substring(this.jitsiLink.lastIndex
      
    //   // options.roomName = 
    //   console.log(this.jitsiLink)
    // }
    // else{
    //   // options.roomName=options.roomName+"/"+uuidv4() doc/ rmp / rmp
    //   this.jitsiLink = domain + "/" + options.roomName;
    //   console.log(this.jitsiLink)
    // }

    if (this.service.userDetails.docId == null) {
      options.userInfo.displayName = hwName;
      this.jitsiLink = domain + "/" + options.roomName;
      console.log(this.jitsiLink)
    }
    else {
      this.jitsiLink = domain + "/" + options.roomName;
      console.log(this.jitsiLink)
    }

    // console.log(this.jitsiLink)
    // console.log(uuidv4())
    // console.log(options.roomName)

    // const link = domain + "/" + options.roomName;
    this.api = new JitsiMeetExternalAPI(domain, options);

    var self = this;
    this.api.addEventListener("participantLeft", function () {
      this.uuId = null;
      self.api.dispose();
      self.callEnded = true;
      if (self.service.userDetails.docId != null) {
        self.completeAppointment();
      }//else self.router.navigate(["healthworker/dashboard"]);
      else 
      {
        //this.doctorService
        //.updateAppointmentStatus(
        //  this.service.consultation.consultationId,
        //  "COMPLETED"
        //)
        console.log("Redirecting from video to Notification Page")
        localStorage.setItem('frompage',"videopage");
        self.router.navigate(["healthworker/notifications"]);
      }
    });
//can u try now and check
    this.api.addListener("readyToClose", function () {
      this.loading = true;
      self.api.dispose();
      self.callEnded = true;
      // setTimeout(function(){
      //   console.log("Iteration: " );
      //   this.loading = false;
      // }, 10000);
      // console.log("readytoclose");
      if (self.service.userDetails.docId != null) {
        self.completeAppointment();
      } else{
        self.router.navigate(["healthworker/appointmentList"]);
      } 
    });

    console.log(this.jitsiLink);
    console.log(this.service.consultation.appDate);
    //if doctor, send video link
    if (this.service.userDetails.docId != null) {
      this.doctorService
        .sendVideoLink(
          this.service.consultation.hwId,
          this.service.consultation.docId,
          this.service.consultation.patId,
          this.service.consultation.docName,
          this.service.consultation.patName,
          this.service.consultation.appDate,
          this.service.consultation.startTime,
          this.jitsiLink,
          this.service.consultation.consultationId
        )
        .subscribe(
          (res) => {
            console.log(res);
            this.notifyhealthworkerService.notifyhealthworker(this.service.consultation.hwId);
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  completeAppointment() {
      this.doctorService
        .updateAppointmentStatus(
          this.service.consultation.consultationId,
          "COMPLETED"
        )
        .subscribe(
          (res: any) => {
            console.log(res);
            this.router.navigate(["doctor/dashboard"]);
          },
          (err) => {
            console.log(err);
          }
        );
  }

  pauseOrUnpauseVideo() {
    this.api.executeCommand("toggleVideo");
    console.log("toggle");
  }
}
