import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '../../user.service';
import { DoctorService } from "../../doctor.service";
import { DEFAULT_PLACEHOLDER_IMG } from '../../constants/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    "(window:click)": "onClick()"
  }
})
export class HeaderComponent implements OnInit {

  isMenuOpen = false;
  showBackBtn: boolean = false;
  dataURL: any = DEFAULT_PLACEHOLDER_IMG;

  constructor(public service: UserService, private router: Router, public doctorService: DoctorService) { 
    this.router.events.subscribe((navChange) => {
      if(navChange instanceof NavigationEnd) {
        this.showBackBtn = (navChange.urlAfterRedirects !== "/doctor/dashboard");
      }
    });
  }

  ngOnInit() {
    console.log("HEADER CALLING EACH")
    this.service.userDetails = JSON.parse(localStorage.getItem('userDetails'))
    if(this.service.userDetails==null)
    this.router.navigate(['user/login'])

    if(this.service.userDetails && this.service.userDetails.docId){
      this.getProfilePic();
    }
  }
   
  toggleMenu(event: any) {    
    event.stopPropagation();
    this.isMenuOpen = !this.isMenuOpen;
    if(this.isMenuOpen==true){
      document.getElementById("mySidenav").style.width = "250px";
    }
    else
    document.getElementById("mySidenav").style.width = "0";
  }

  onClick() {

    // hide sidebar in doctor's and healthworker's mobile view on clicking outside
      this.isMenuOpen = false;
      if(document.getElementById("mySidenav")!=null)
      document.getElementById("mySidenav").style.width = "0";
  }

  goBackToPreviousPage(): void {
    window.history.back();
  }

  getProfilePic() {
    this.doctorService
      .getDoctorProfilePic(this.service.userDetails.docId)
      .subscribe((res: any) => {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = () => {
          this.dataURL = reader.result;
        };
      });
  }

  

}
