import { Component, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';

import { Router, ActivatedRoute } from '@angular/router'
import { UserService } from '../../user.service';
import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';
import { AuthAuthenticationService } from '../../authentication.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { idText } from 'typescript';

@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.css']
})
export class AdminLoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  username: String;
  password: String;
  message: any;
  error = '';
  errorMessage = 'Invalid Credentials';
  successMessage: string;
  invalidLogin = false;
  loginSuccess = false;
  
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private service: UserService,
    private dialog: MatDialog,
    private router: Router, private Authservice: AuthAuthenticationService) { }

  ngOnInit() {
    /* if (this.Authservice.isUserLoggedIn()) {
      this.service.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
      if(this.service.userDetails.role == "DOCTOR")
        this.router.navigate(['doctor/dashboard'])
      else
        this.router.navigate(['user/userDashboard'])
    } */
    this.loginForm = this.formBuilder.group({
      Username: new FormControl('', Validators.required),
      Password: new FormControl('', Validators.required)
    });
  }
  get fval() {
    return this.loginForm.controls;
  }

  login() {
    // this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      //this.Authservice.enableHistory=this.enableReferPatient;
      let resp = this.Authservice.authenticate(this.username, this.password);
      resp.subscribe(data => {
        console.log(data)
        this.Authservice.registerSuccessfulLogin(data, this.username, this.password);
        this.service.userDetails = data;
        this.invalidLogin = false;
        this.loginSuccess = true;
        this.successMessage = 'Login Successful.';
        this.message = data;
        this.router.navigate(['/adminlanding']);
        
      }, () => {
        this.invalidLogin = true;
        this.loginSuccess = false;
      });
    }
  }


  forgotpassword() {
    const dialog = new MatDialogConfig();
    dialog.disableClose = true;
    dialog.autoFocus = true;
    dialog.maxWidth = "100vw";
    dialog.maxHeight = "100vh"
    this.dialog.open(ForgotpasswordComponent, dialog)
  }
}
