import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotifyhealthworkerService {
 
 headers: HttpHeaders;
  constructor( private http: HttpClient) { }

   public notifyhealthworker(hwId: any) {
     console.log('Consulation Details : ',hwId);
     let token = localStorage.getItem('accessToken'); 
    console.log(token);
    this.headers= new HttpHeaders({
      "Authorization": "Bearer " + token 
    })
      let  dataFromServer =   this.http.get('/api/healthworkers/notify/'+hwId,{headers:this.headers}).subscribe(res=>{
       console.log('res',res);
       return res;
     });
       console.log('calling compkete : ',dataFromServer);
  }
}
