import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '../../user.service';
import { HealthworkerService } from "../../healthworker/healthworker.service";
import { DoctorService } from '../../doctor.service';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { NgxImageCompressService } from 'ngx-image-compress';
import { MedicationDTO, PrescriptionAndClincalNotes } from '../../models/prescription-and-clincal-notes';
import { DEFAULT_PLACEHOLDER_IMG } from '../../constants/constants';

@Component({
  selector: 'app-view-appointments',
  templateUrl: './view-appointments.component.html',
  styleUrls: ['./view-appointments.component.css']
})
export class ViewAppointmentsComponent implements OnInit {

  appointments: any = []
  fileNames: any = []
  base64ProfilePic: any = DEFAULT_PLACEHOLDER_IMG;
  healthRecords: any = []
  showBackBtn: boolean = false;
  healthRecord: any
  healthRecordPreview: any
  formData: any = new FormData()
  healthRecordsUploaded: boolean[] = []
  uploadedHealthRecords: any[] = []
  loading: boolean = false
  filename: any
  healthrecordloading: boolean[] = []
  invoice: any = []
  oldPrescriptionList: PrescriptionAndClincalNotes[] = [];
  oldPrescriptionsLoaded: boolean = false
  prescriptionImage: any
  prescriptionPictureUpload: boolean = false
  prescriptionCaptured: boolean = false
  base64Prescription: any
  patientDetails: any;
  enableInvoiceModal : boolean;

  constructor(private doctorService: DoctorService, public service: UserService, private healthworkerService:HealthworkerService,
    private router: Router, private snackbar: MatSnackBar, public ng2ImgMax: Ng2ImgMaxService,
    private imageCompress: NgxImageCompressService) {
      this.router.events.subscribe((navChange) => {
        if(navChange instanceof NavigationEnd) {
          this.showBackBtn = (navChange.urlAfterRedirects !== "/doctor/dashboard");
        }
      });
     }

  ngOnInit() {

    window["ReactNativeWebView"] &&
    window["ReactNativeWebView"].postMessage(
      JSON.stringify({ "backButtonDisabled": false,"change": true,"path": "patient-appointment" })
    );

    this.service.userDetails = JSON.parse(localStorage.getItem('userDetails'))
    console.log(this.service.userDetails.hwId)
    if(this.service.userDetails==null)
    this.router.navigate(['healthworker/login'])

    /*  $(window).on('popstate', function() {
      $('.modal').modal('hide');
      $( ".modal-backdrop" ).remove();
      $( ".in" ).remove();
    }); */


    this.getInvoice_dct()
    this.getPatientAppointments()
  }

  showInvoice(consultationId){
    localStorage.setItem("InvConsltID",consultationId);
    this.router.navigate(['/doctor/invoice']);
  }

  getInvoice_dct(){
    let consltID = localStorage.getItem("InvConsltID");
  this.healthworkerService.getInvoice(consltID).subscribe((res: any)=>{
      console.log("Result", res);
      this.invoice = res.response;
      this.loading = false;
     this.enableInvoiceModal = true;
     },
     err=>{
       console.log(err)
     });
  }

  getPatientAppointments(){
    this.loading = true
    this.doctorService.getPatientAppointments(this.service.patient.patId).subscribe((res: any)=>{
        console.log(res)
        this.appointments = res.response
        let i = 0
        this.loading = false
    },
    err=>{
        this.loading = false
        console.log(err)
    })
    if(this.patientDetails.profilePictureEntity) this.base64ProfilePic = 'data:'+this.patientDetails.profilePictureEntity.fileType+';base64,' + this.patientDetails.profilePictureEntity.imageContents

      console.log(this.base64ProfilePic);
  }

  prescriptionUpload(e, index, consultationId){
    this.prescriptionImage = e.target.files[0]
    let size = e.target.files[0].size / (1024 * 1024)
    console.log(this.prescriptionImage)
    if(this.prescriptionImage!=null){
      if(size>15)
          this.snackbar.open("File size can't be more than 15 mb!!", '',{
            duration: 3000,
            verticalPosition: 'bottom'
          })
      else{
        this.convertToBase64(this.prescriptionImage,consultationId, index)
      }
    }
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  returnToPatientList(){
    this.router.navigate(["doctor/patientList"]);
  }

  convertToBase64(file: File, consultationId: any, index: number){
    const reader: FileReader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = ()=> {
      this.base64Prescription = reader.result
      this.submitNewPrescriptionAndClinicalNotes(consultationId, index)
    }
  }

  callPrescriptionUploadApi(consultationId: any, index: number){
    this.doctorService.addPrescriptionAndClinicalNotes(this.formData, this.service.patient.patId, consultationId).subscribe((res: any) => {
      console.log(res)
      this.loading = false
      if (res.responseCode == "201") {
        //this.oldPrescriptionList.push(res.response)
        // this.getOldPrescriptionList()
        this.prescriptionPictureUpload = true
        this.prescriptionCaptured = false
        this.formData = new FormData()
        this.snackbar.open("Prescription Submitted!!!", '', {
          duration: 3000,
          verticalPosition: 'bottom'
        })
        this.getPatientAppointments()
      }
    },
      err => {
        this.loading = false
        console.log(err)
      })
  }

  submitNewPrescriptionAndClinicalNotes(consultationId: any, index: number) {
    this.loading = true
    this.formData = new FormData()
    this.base64Prescription.replace(/^data:image\/[a-z]+;base64,/, "")
    console.log('Size before compression' + this.prescriptionImage.size)
    this.imageCompress.compressFile(this.base64Prescription, 50, 50).then(
      result => {
        console.warn('Size in bytes is now:', this.imageCompress.byteCount(result))
        let p = result.replace(/^data:image\/[a-z]+;base64,/, "")
        const imageBlob = this.dataURItoBlob(p)
        const imageFile = new File([imageBlob], this.prescriptionImage.name, { type: 'image/jpg' })
        this.formData.append('clinicalNotePic', imageFile)
        this.callPrescriptionUploadApi(consultationId, index)
    })

  }

  prescriptionUploaded(event:any){
    if(event.updated){
      this.getPatientAppointments();
    }
  }

  goBackToPreviousPage(): void {
    window.history.back();
  }

  showPrescription(data:any){
    this.service.patient = data;
    this.router.navigate(['/doctor/prescription']);
  }


}
