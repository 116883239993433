import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'docsearch'
})
export class DocSearchPipe implements PipeTransform {

  transform(items: any, args?: any): any {
    if (!items) {return null;}
    if (!args) {return items;}
    // filter items array, items which match patient displayName and return true will be
    // kept, false will be filtered out
    return items.filter(item => item.displayName.toLowerCase().includes(args.toLowerCase()))
  }

}
