export class PrescriptionAndClincalNotes {

    subSlotId: any;
    slotId: any; 
    docId: string;
    hwId:string;
    patId: string; 
    consultationDate: Date;
    cnsltId: any;
    symptoms: any;
    consultationReason: string;
    clinicalNotes: string;
    medicationsDTOs: MedicationDTO[];
    
}

export class MedicationDTO{

    medicineName:string;
    quantity: any;
    frequency: string;
    noOfDays: number;
    measurementUnit: string;
    instructions:string;

}
