import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UserService } from '../../user.service';
import { DatePipe } from '@angular/common';
import { Prescription } from '../../models/prescription';
import { Router } from '@angular/router';
import { DoctorService } from '../../doctor.service';
import { MedicationDTO, PrescriptionAndClincalNotes } from '../../models/prescription-and-clincal-notes';
import { MatSnackBar } from '@angular/material';
import { HealthworkerService } from '../../healthworker/healthworker.service';
import { DomSanitizer } from '@angular/platform-browser';
import { JitsiVideoComponent } from '../jitsi-video/jitsi-video.component';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { NgxImageCompressService } from 'ngx-image-compress';
import {ThemePalette} from '@angular/material/core';
import { DEFAULT_PLACEHOLDER_IMG } from '../../constants/constants';
import { Lightbox, LightboxConfig, LightboxEvent, LIGHTBOX_EVENT, IEvent, IAlbum } from 'ngx-lightbox';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss']
})
export class VideoCallComponent implements OnInit {
  _albums = [];
  patientDetails: any = {};
  api: any;
  callEnded = false;
  webPrescriptionTab = true;
  mobilePrescriptionTab = false;
  mobileVideoCallTab = true;
  webVideoCallTab = true;
  patientDetailsTab = false;
  labReportTab=true;
  prescriptionTab=false;
  otherRecordsTab=false;
  feedbackTab = false;
  currentDate: Date = new Date();
  prescriptionAndClinicalNotes: PrescriptionAndClincalNotes = new PrescriptionAndClincalNotes()
  medications: MedicationDTO[] = [];
  medication: MedicationDTO = new MedicationDTO()
  clinicalNote: string = ''
  oldPrescriptionList: PrescriptionAndClincalNotes[] = [];
  dosageList: any = ['1-0-0', '1-0-1', '1-1-0', '0-1-0', '0-0-1', '0-1-1', '1-1-1'];
  searchedMedicine: any;

  formData: any = new FormData()
  prescriptionPictureUpload: boolean = false
  prescriptionCaptured: boolean = false
  prescriptionPic: any 
  base64Prescription: any
  height: any = {}
  patientIdProofBase64: any;
  base64ProfilePic: any = DEFAULT_PLACEHOLDER_IMG;
  medicalHistory: any = []
  healthProfile: any = []
  activeTab: any = 'tab-1'
  videoPaused: boolean = false
  color: ThemePalette = 'accent';
  checked = false
  imageArray:any;

  public pagingStartIndex: number;
  public pagingEndIndex: number;
  public pageSize: number;
  public numberOfPages: number;
  public pageNum: number[];
  public currentPage: number;
  public displayData: any;
  
  public prescPagingStartIndex: number;
  public prescPagingEndIndex: number;
  public prescPageSize: number;
  public prescNumberOfPages: number;
  public prescPageNum: number[];
  public prescCurrentPage: number;
  public displayDataPrescriptions: any;

  public orPagingStartIndex: number;
  public orPagingEndIndex: number;
  public orPageSize: number;
  public orNumberOfPages: number;
  public orPageNum: number[];
  public orCurrentPage: number;
  public displayDataOther: any;
  
  @ViewChild('video', { static: false }) videoElement: ElementRef;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  constraints = {
    video: {
      facingMode: "environment",
      width: { ideal: 4096 },
      height: { ideal: 2160 }
    }
  };

  videoWidth = 0;
  videoHeight = 0;
  loading: boolean = false
  showOldPrescriptions: boolean = false
  oldPrescriptionsLoaded: boolean = false
  healthRecords: any = []
  manualPrescriptions: any = []
  textPrescriptions: any = []
  OtherRecords: any = []
  base64HealthRecords: any = []
  base64ManualPrescriptions: any = []
  base64OtherRecords: any = []
  healthRecordsLoaded = false
  prescriptionImage: any
  oldPrescriptionLists:any=[];
  indexid: number;
  drugname:any;
  quantity:any;
  dosage:any;
  intaketm:any;
  instructions: any;
  noOfDays:number;
  @ViewChild(JitsiVideoComponent, {static: false}) child: JitsiVideoComponent;
  public medicalHistoryQuestion:string[]=['Previous / chronic health issues',
  'Hosipitalized in last 12 months', 'Lab Reports from last 12 months', 'Food / Medicine allergy',
  'Currently using medicines'];
  


  constructor(private _lightbox: Lightbox,public service: UserService, private datePipe: DatePipe, 
    private router: Router, public doctorService: DoctorService, private snackbar: MatSnackBar,
    public healthworkerService: HealthworkerService, private renderer: Renderer2, 
    public _d: DomSanitizer, private ng2ImgMax: Ng2ImgMaxService, private imageCompress: NgxImageCompressService) 
    {
      this.pagingStartIndex = 0;
      this.displayData = [];
      this.pageNum = [];
      this.pageSize = 10; //default page size
      this.currentPage = 1;

      this.prescPagingStartIndex = 0;
      this.displayDataPrescriptions = [];
      this.prescPageNum = [];
      this.prescPageSize = 10; //default page size
      this.prescCurrentPage = 1;

      this.orPagingStartIndex = 0;
      this.displayDataOther = [];
      this.orPageNum = [];
      this.orPageSize = 10; //default page size
      this.orCurrentPage = 1;
    }

  ngOnInit() {
    window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(JSON.stringify({"backButtonDisabled":false,"change": false,"path":"Doctor Video Call"}))
    this.service.userDetails = JSON.parse(localStorage.getItem('userDetails'))
    this.service.consultation = JSON.parse(localStorage.getItem('consultation'))
    //console.log(this.service.consultation)
    
    this.getPatientDetails()

      this.prescriptionAndClinicalNotes.subSlotId = this.service.consultation.subSlotId
      this.prescriptionAndClinicalNotes.slotId = this.service.consultation.slotId
      this.prescriptionAndClinicalNotes.docId = this.service.consultation.docId
      this.prescriptionAndClinicalNotes.hwId = this.service.consultation.hwId
      this.prescriptionAndClinicalNotes.patId = this.service.consultation.patId
      this.prescriptionAndClinicalNotes.consultationDate = this.currentDate
      this.prescriptionAndClinicalNotes.consultationReason = this.service.consultation.reason
      this.prescriptionAndClinicalNotes.cnsltId = this.service.consultation.conId
      this.prescriptionAndClinicalNotes.symptoms = ''
      this.prescriptionAndClinicalNotes.medicationsDTOs = []
      this.prescriptionAndClinicalNotes.clinicalNotes = ''

      let a = JSON.stringify(this.prescriptionAndClinicalNotes)
      this.formData.append('clinicalNotesDTO', a )

      this.mobileVideoCallTab = true;
      this.mobilePrescriptionTab = false; 
      this.patientDetailsTab = false;

      //console.log(this.prescriptionAndClinicalNotes)
      //this.oldPrescriptionList[0] = this.prescriptionAndClinicalNotes
      //console.log(this.oldPrescriptionList)
      this.getOldPrescriptionList()
      this.getConsultationDetails()
      this.getPatientHealthRecords()
      this.getPatientPrescriptions()
      this.getPatientOtherRecords()
  }

  getPatientDetails(){
    this.healthworkerService.getPatientProfileDetails(this.service.consultation.patId, 'ENG').subscribe((res: any)=>{
      console.log(res)
      this.patientDetails = res.response
      this.height = this.patientDetails.height
      if(this.patientDetails.patientIDProofEntity)
      this.patientIdProofBase64 = 'data:image/png;base64,' + this.patientDetails.patientIDProofEntity.imageContents 
      if(this.patientDetails.profilePictureEntity)
      this.base64ProfilePic = 'data:image/png;base64,' + this.patientDetails.profilePictureEntity.imageContents
      if(this.patientDetails.previousMedicalResponseModel!=null)
      this.medicalHistory = this.patientDetails.previousMedicalResponseModel.previousMedicalDetailsModels
      if(this.patientDetails.healthProfileResponseModel!=null)
      this.healthProfile = this.patientDetails.healthProfileResponseModel.healthProfileDetailsModel
    },
   err=>{
     console.log(err)
   })
  }

  backToAppointments() {

    if (this.prescriptionAndClinicalNotes.medicationsDTOs.length == 0) {
      alert("Kindly add a prescription before ending the call")
      return;
    }
    this.router.navigate(['doctor/dashboard'])
  }

  checkIfOldPrescriptionsLoaded(){
    if(this.oldPrescriptionsLoaded==false) //still not loaded
      this.loading = true;
    else if(this.service.userDetails && this.service.userDetails.docId == null){
      this.loading = true;
      this.oldPrescriptionList=[];
      this.oldPrescriptionsLoaded=false;
      this.getOldPrescriptionList();
    }
    this.showOldPrescriptions = true;  
  }

  getOldPrescriptionList(){
    if(this.showOldPrescriptions){
      this.loading = true;
    }
    this.doctorService.getOldPrescriptions(this.service.consultation.patId).subscribe((res: any)=>{
         console.log(res)
         this.oldPrescriptionList = res.response
         this.oldPrescriptionLists = res.response
         this.oldPrescriptionsLoaded = true
         this.loading = false
         /* res.response.forEach(element => {
           this.oldPrescriptionList.push(element)
         }); */
        
      },
      err=>{
        console.log(err)
      })
    this.doctorService.getTextprescription(this.service.consultation.conId).subscribe((res: any) => {
      console.log("Prescription Text:");
      console.log(res);
      this.textPrescriptions = res;
    },
      err => {
        console.log(err)
      })  
  }

  addPrescription(){

    //add current date prescription to oldPrescriptionList temporarily and to prescriptionAndClinicalNotes
    this.oldPrescriptionList[0].medicationsDTOs.push(this.medication)
    console.log(this.oldPrescriptionList[0].medicationsDTOs)
    //this.prescriptionAndClinicalNotes.medicationsDTOs.push(this.medication)
    console.log(this.prescriptionAndClinicalNotes.medicationsDTOs)
    this.snackbar.open("Medication added!!", '', {
      duration: 3000,
      verticalPosition: 'bottom'
    })
    //reinitialise medication
    this.medication = new MedicationDTO()
  }

  addClinicalNote(){
    //add current date clinical note to oldPrescriptionList temporarily and to prescriptionAndClinicalNotes
    this.oldPrescriptionList[0].clinicalNotes = this.clinicalNote  
    this.prescriptionAndClinicalNotes.clinicalNotes = this.clinicalNote
    console.log(this.prescriptionAndClinicalNotes)
    this.snackbar.open("Clinical Note added!!", '', {
      duration: 3000,
      verticalPosition: 'bottom'
    })
    //reinitialize clinical note
    this.clinicalNote = ''
  }

  convertToBase64(file: File){
    const reader: FileReader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = ()=> {
      this.base64Prescription = reader.result
      this.submitNewPrescriptionAndClinicalNotes()
    }
    reader.onerror =() =>{
      this.loading = false;
    }
  }

  callPrescriptionUploadApi(){
    this.doctorService.addPrescriptionAndClinicalNotes(this.formData, this.service.consultation.patId, this.service.consultation.consultationId).subscribe((res: any) => {
      console.log(res)
      this.loading = false
      if (res.responseCode == "201") {
        //this.oldPrescriptionList.push(res.response)
        this.getOldPrescriptionList()
        this.prescriptionPictureUpload = true
        this.prescriptionCaptured = false
        this.formData = new FormData()
        this.snackbar.open("Prescription Submitted!!!", '', {
          duration: 3000,
          verticalPosition: 'bottom'
        })

      }
    },
      err => {
        this.loading = false
        console.log(err)
      })
  }

  submitNewPrescriptionAndClinicalNotes() {
    this.loading = true
    this.formData = new FormData()
    this.base64Prescription.replace(/^data:image\/[a-z]+;base64,/, "")
    console.log('Size before compression' + this.prescriptionImage.size)
    this.imageCompress.compressFile(this.base64Prescription, 50, 50).then(
      result => {
        console.warn('Size in bytes is now:', this.imageCompress.byteCount(result))
        let p = result.replace(/^data:image\/[a-z]+;base64,/, "")
        const imageBlob = this.dataURLtoBlob(p)
        const imageFile = new File([imageBlob], this.prescriptionImage.name, { type: 'image/jpg' })
        this.formData.append('clinicalNotePic', imageFile)
        this.callPrescriptionUploadApi()
    })

    /* this.ng2ImgMax.compressImage(this.prescriptionImage, 0.3).subscribe(
      result => {
        this.formData = new FormData()
        let file = new File([result], result.name);
        this.formData.append('clinicalNotePic', file);
        console.log(file.size)
        this.doctorService.addPrescriptionAndClinicalNotes(this.formData, this.service.consultation.patId, this.service.consultation.consultationId).subscribe((res: any) => {
          console.log(res)
          this.loading = false
          if (res.responseCode == "201") {
            this.getOldPrescriptionList()
            this.prescriptionPictureUpload = true
            this.prescriptionCaptured = false
            this.formData = new FormData()
            this.snackbar.open("Prescription Submitted!!!", '', {
              duration: 3000,
              verticalPosition: 'bottom'
            })

          }
        },
          err => {
            this.loading = false
            console.log(err)
          })
      },
      err=>{
          this.loading = false
          this.snackbar.open(err.reason, '', {
          duration: 3000,
          verticalPosition: 'bottom'
        })
      })  */

  }

  handleJitsiVideo(){
    console.log(this.child.api.isVideoMuted())
    this.child.api.isVideoMuted().then(value =>{
      console.log(value)
      if(value==true){
        this.child.pauseOrUnpauseVideo()
        console.log("unpausing........." )
      }
    })
  }
  
  onPrescriptionClick(){
    console.log(this.child.api.isVideoMuted())
    this.child.api.isVideoMuted().then(value =>{
      console.log(value)
      if(value==false){
        this.child.pauseOrUnpauseVideo()
        console.log("pausing........." )
      }
    })
  }

  startCamera() {
    if(this.videoPaused==false){
      this.child.pauseOrUnpauseVideo()
      this.videoPaused = true
    }
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
        navigator.mediaDevices.getUserMedia(this.constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
    }
    else {
        console.log('Sorry, camera not available.');
    }
  }

  handleError(error) {
    console.log('Error: ', error);
  }

  attachVideo(stream) {
    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
    this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
      this.videoHeight = this.videoElement.nativeElement.videoHeight;
      this.videoWidth = this.videoElement.nativeElement.videoWidth;
    });
  }

  capture() {
    this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth)
    this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight)
    this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0)
    this.prescriptionCaptured = true
 
    let dataURL = this.canvas.nativeElement.toDataURL()
    console.log(dataURL)
    this.base64Prescription = dataURL
    let blobImage = this.dataURLtoBlob(dataURL)
    console.log(blobImage)
    var file = new File([blobImage], this.service.consultation.patId + "_prescription.png", { type: blobImage.type});
    console.log(file)
    this.prescriptionImage = file
  }

  prescriptionUpload(e){
    this.loading=true;
    this.prescriptionImage = e.target.files[0]
    let size = e.target.files[0].size / (1024 * 1024)
    console.log(this.prescriptionImage)
    if(this.prescriptionImage!=null){
      if(size>15){
        this.loading = false;
        this.snackbar.open("File size can't be more than 15 mb!!", '',{
          duration: 3000,
          verticalPosition: 'bottom'
        })
      }
      else{
        this.convertToBase64(this.prescriptionImage)
      }
    }
  }

  prescriptionUploaded(event:any){
    if(event.updated){
      this.getOldPrescriptionList()
      this.prescriptionPictureUpload = true
      this.prescriptionCaptured = false
        
    }
  }
  
  dataURLtoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });    
    return blob;
  }

  getConsultationDetails(){
    this.doctorService.getConsultationById(this.service.consultation.conId).subscribe((res: any)=>{
      console.log(res)
    },
    err=>{
      console.log(err)
    })
  }

  changeActiveTab(value: any){
    this.activeTab = value
    console.log(this.activeTab)
    if(this.activeTab=='tab-3' && this.healthRecordsLoaded==false)
    this.loading= true
  }

  keyword = 'medicine';
  medicineData = [];
  medicineSelect(item) {
    console.log("1");
  }

  getMedicineName(val: string) {
    this.doctorService.getMedicinelistforsearch(val).subscribe((res: any) => {
      this.medicineData=res;
      // console.log(this.medicineData);
    });
  }

  medFocused(e) {
    // console.log("3");
  }

  addPrescriptionList(){
    let consultationData = JSON.parse(localStorage.getItem("consultation"));
    if (this.drugname== null) {
      this.snackbar.open("Enter Medicine/Drug Name", "", {
        duration: 3000,
        verticalPosition: "bottom",
      });
      return;
    } else if (this.drugname.medicine == null) {
      this.snackbar.open("Enter Medicine/Drug Name", "", {
        duration: 3000,
        verticalPosition: "bottom",
      });
      return;
    } else  if (this.quantity == null) {
      this.snackbar.open("Enter Quantity", "", {
        duration: 3000,
        verticalPosition: "bottom",
      });
      return;
    } else if (this.dosage == null) {
      this.snackbar.open("Enter Dosage", "", {
        duration: 3000,
        verticalPosition: "bottom",
      });
      return;
    }
    else if (this.intaketm == null) {
      this.snackbar.open("Select Intake Time", "", {
        duration: 3000,
        verticalPosition: "bottom",
      });
      return;
    } else if (this.noOfDays == null) {
      this.snackbar.open("Enter No. of Days", "", {
        duration: 3000,
        verticalPosition: "bottom",
      });
      return;
    }
    
    let objs = {
      "consultationId": consultationData.conId,
      "medicine": this.drugname.medicine,
      "description": this.instructions,
      "quantity": this.quantity,
      "dosage": this.dosage,
      "intaketime": this.intaketm,
      "noOfDays": this.noOfDays
    }

    this.doctorService.addTextprescriptionup(objs).subscribe((res:any)=>{
      console.log(res);
      if(res[0].date){        

        this.snackbar.open("Prescription added Successfully", "", {
          duration: 3000,
          verticalPosition: "bottom",
        });

        this.drugname = null;
        this.instructions = null;
        this.quantity = null;
        this.dosage = null;
        this.intaketm = null;
        this.noOfDays=null;
        this.getPatientPrescriptions();
      }
    })

  }

  //for lab reports
  getPatientHealthRecords(){
    this.doctorService.getLabReports(this.service.consultation.patId).subscribe((res: any)=>{
      console.log(res)
      this.loading = false
      this.healthRecordsLoaded = true
      this.healthRecords = res.response
      // this.healthRecords.forEach(element => {
      //   if(element.fileType!='application/pdf')
      //   this.base64HealthRecords.push('data:image/png;base64,' + element.imageContents)

      //   else
      //   this.base64HealthRecords.push('data:application/pdf;base64,' + element.imageContents)
      // });
      this.displayPageHR(1);
    },
    err=>{
      console.log(err)
    })
  }

  //for manually uploaded prescriptions
  getPatientPrescriptions(){
    this.doctorService.getManualPrescriptions(this.service.consultation.patId).subscribe((res: any)=>{
      console.log(res)
      this.loading = false
      this.healthRecordsLoaded = true
      this.manualPrescriptions = res.response
      // this.manualPrescriptions.forEach(element => {
      //   if(element.fileType!='application/pdf')
      //   this.base64ManualPrescriptions.push('data:image/png;base64,' + element.imageContents)

      //   else
      //   this.base64ManualPrescriptions.push('data:application/pdf;base64,' + element.imageContents)
      // });
      this.displayPagePrescriptions(1);
    },
    err=>{
      console.log(err)
    })
    this.doctorService.getTextprescription(this.service.consultation.conId).subscribe((res: any) => {
      console.log("Prescription Text:");
      console.log(res);
      this.textPrescriptions=res;
    },
      err => {
        console.log(err)
      })
  }

  //for manually uploaded other records
  getPatientOtherRecords(){
    this.doctorService.getOtherRecords(this.service.consultation.patId).subscribe((res: any)=>{
      console.log(res)
      this.loading = false
      this.healthRecordsLoaded = true
      this.OtherRecords = res.response
      // this.OtherRecords.forEach(element => {
      //   if(element.fileType!='application/pdf')
      //   this.base64OtherRecords.push('data:image/png;base64,' + element.imageContents)

      //   else
      //   this.base64OtherRecords.push('data:application/pdf;base64,' + element.imageContents)
      // });
      this.displayPageOR(1);
    },
    err=>{
      console.log(err)
    })
  }

  public displayPageHR(pageNumber: number) {
    console.log(this.pageSize)
    this.pagingStartIndex = this.pageSize * (pageNumber - 1);
    this.pagingEndIndex =
      Number.parseInt(this.pagingStartIndex.toString()) +
      Number.parseInt(this.pageSize.toString()) -
      1;
    this.displayData = [];
    this.pageNum = [];
    let mod: number = this.healthRecords.length % this.pageSize;
    if (mod > 0)
      this.numberOfPages =
        Math.floor(this.healthRecords.length / this.pageSize) + 1;
    else this.numberOfPages = this.healthRecords.length / this.pageSize;

    console.log("Numberofpages" + this.numberOfPages);

    for (let i = 0; i < this.numberOfPages; i++) {
      this.pageNum.push(i + 1);
    }

    //update the data to be displayed based on above changes
    for (let i = this.pagingStartIndex; i <= this.pagingEndIndex; i++) {
      if (this.healthRecords[i] != null) {
        this.displayData.push(this.healthRecords[i]);
      }
    }

    //update current page
    this.currentPage = pageNumber;
  }

  public displayPagePrescriptions(pageNumber: number) {
    this.prescPagingStartIndex = this.prescPageSize * (pageNumber - 1);
    this.prescPagingEndIndex =
      Number.parseInt(this.prescPagingStartIndex.toString()) +
      Number.parseInt(this.prescPageSize.toString()) -
      1;
    this.displayDataPrescriptions = [];
    this.prescPageNum = [];
    let mod: number = this.manualPrescriptions.length % this.prescPageSize;
    if (mod > 0)
      this.prescNumberOfPages =
        Math.floor(this.manualPrescriptions.length / this.prescPageSize) + 1;
    else this.prescNumberOfPages = this.manualPrescriptions.length / this.prescPageSize;

    console.log("NumberOfPages" + this.prescNumberOfPages);

    for (let i = 0; i < this.prescNumberOfPages; i++) {
      this.prescPageNum.push(i + 1);
    }

    //update the data to be displayed based on above changes
    for (let i = this.prescPagingStartIndex; i <= this.prescPagingEndIndex; i++) {
      if (this.manualPrescriptions[i] != null) {
        this.displayDataPrescriptions.push(this.manualPrescriptions[i]);
      }
    }

    //update current page
    this.prescCurrentPage = pageNumber;
  }

  public displayPageOR(pageNumber: number) {
    this.pagingStartIndex = this.pageSize * (pageNumber - 1);
    this.pagingEndIndex =
      Number.parseInt(this.pagingStartIndex.toString()) +
      Number.parseInt(this.pageSize.toString()) -
      1;
    this.displayDataOther = [];
    this.pageNum = [];
    let mod: number = this.OtherRecords.length % this.pageSize;
    if (mod > 0)
      this.numberOfPages =
        Math.floor(this.OtherRecords.length / this.pageSize) + 1;
    else this.numberOfPages = this.OtherRecords.length / this.pageSize;

    console.log("Numberofpages" + this.numberOfPages);

    for (let i = 0; i < this.numberOfPages; i++) {
      this.pageNum.push(i + 1);
    }

    //update the data to be displayed based on above changes
    for (let i = this.pagingStartIndex; i <= this.pagingEndIndex; i++) {
      if (this.OtherRecords[i] != null) {
        this.displayDataOther.push(this.OtherRecords[i]);
      }
    }

    //update current page
    this.currentPage = pageNumber;
  }

 /* callImage(id:any){
    this.loading = true
    if(localStorage.getItem(id) != null && localStorage.getItem(id) != ""){
      this.loading = false;
      this.healthRecordsLoaded = true;
      this.imageArray = localStorage.getItem(id);
      return;
    }
    this.service.getImageById(id).subscribe((res: any)=>{
      console.log(res)
      this.loading = false
      this.healthRecordsLoaded = true
      if(res.response.documentType!='application/pdf')
        this.imageArray='data:image/png;base64,' + res.response.imageContents

      else
        this.imageArray='data:application/pdf;base64,' + res.response.imageContents
      
      // console.log(this.imageArray)
    },
    err=>{
      console.log(err)
    })
  }*/

  storePrescriptionInLocalStorage(){
    for(let i = 0; i < this.displayData.length; i++){
      this.service.getImageById(this.displayData[i].id).subscribe((res: any)=>{
        if(res.response.documentType!='application/pdf')
          localStorage.setItem(this.displayData[i].id,'data:image/png;base64,' + res.response.imageContents);
        else
        localStorage.setItem(this.displayData[i].id,'data:application/pdf;base64,' + res.response.imageContents);
      },
      err=>{
        console.log(err)
      })
    }
    for(let i = 0; i < this.displayDataOther.length; i++){
      this.service.getImageById(this.displayDataOther[i].id).subscribe((res: any)=>{
        if(res.response.documentType!='application/pdf')
          localStorage.setItem(this.displayDataOther[i].id,'data:image/png;base64,' + res.response.imageContents);
        else
        localStorage.setItem(this.displayDataOther[i].id,'data:application/pdf;base64,' + res.response.imageContents);
      },
      err=>{
        console.log(err)
      })
    }
      for (let i = 0; i < this.displayDataPrescriptions.length; i++) {
        this.service.getImageById(this.displayDataPrescriptions[i].id).subscribe((res: any)=>{
          if(res.response.documentType!='application/pdf')
            localStorage.setItem(this.displayDataPrescriptions[i].id,'data:image/png;base64,' + res.response.imageContents);
          else
          localStorage.setItem(this.displayDataPrescriptions[i].id,'data:application/pdf;base64,' + res.response.imageContents);
        },
        err=>{
          console.log(err)
        })
    }
  }

  
  _albums_othr=[];
  callImage_other(i:number,id:any,displayDataOther){
    this.indexid=i;
    this.displayDataOther=displayDataOther;
    this.loading = true
    let image_Arrays = '';
    this._albums_othr.length=0;
    for(let j=0;j<displayDataOther.length;j++){
  
      this.service.getImageById(displayDataOther[j].id).subscribe((res: any)=>{
        console.log(res)
        this.loading = false
        this.healthRecordsLoaded = true
  
        if(res.response.documentType!='application/pdf'){
          if(res.response.imageContents!= null){
            image_Arrays = 'data:image/jpg;base64,' + res.response.imageContents;
  
          }  
          else{
            image_Arrays = '../../../assets/images/notfound.png';
          }
        }else{
          image_Arrays = '../../../assets/images/notfound.png';
        }
        
       const albums = {
           src: image_Arrays,
         
       };
    
        this._albums_othr.push(albums);
        
      },
      err=>{
        console.log(err)
      })
    }
   
    console.log(this._albums_othr)
  
  }
  


  Arr_alb=[];
  getimgarray_Lab(index: number): void {
    // open lightbox
    //alert(index);
    //console.log(this._albums);
    this.Arr_alb.length=0;
    for (let i = 0; i < this._albums_lab.length; i++) {
      const src =  this._albums_lab[i].src;
         
      const album = {
         src: src,
         
      };

      this.Arr_alb.push(album);
    }
   // this._lightbox.open(this._albums, index);
    this._lightbox.open(this.Arr_alb, index, { wrapAround: true, showImageNumberLabel: true,alwaysShowNavOnTouchDevices: true,fitImageInViewPort:true });
  }



Arr_other=[];
getimgarray_other(index: number): void {
  this.Arr_other.length=0;
  for (let i = 0; i < this._albums_othr.length; i++) {
    const src =  this._albums_othr[i].src;
    const album = {
       src: src,
    };
    this.Arr_other.push(album);
  }

  this._lightbox.open(this.Arr_other, index, { wrapAround: true, showImageNumberLabel: true,alwaysShowNavOnTouchDevices: true,fitImageInViewPort:true });

}

Arr_Pres=[];
getimgarray_Prescription(index: number): void {
  
  this.Arr_Pres.length=0;
  for (let i = 0; i < this._albums_Pres.length; i++) {
    const src =  this._albums_Pres[i].src;
       
    const album = {
       src: src,
       
    };

    this.Arr_Pres.push(album);
  }
 // this._lightbox.open(this._albums, index);
  this._lightbox.open(this.Arr_Pres, index, { wrapAround: true, showImageNumberLabel: true,alwaysShowNavOnTouchDevices: true,fitImageInViewPort:true });



}

_albums_lab=[];
 callImage(i:number,id:any,displayData){
    this.indexid=i;
    this.displayData=displayData;
    //alert(this.indexid);
    this.loading = true
    let image_Arrays = '';
    this._albums_lab.length=0;
    for(let j=0;j<displayData.length;j++){

      this.service.getImageById(displayData[j].id).subscribe((res: any)=>{
        console.log(res)
        this.loading = false
        this.healthRecordsLoaded = true

        if(res.response.documentType!='application/pdf'){
          if(res.response.imageContents!= null){
            image_Arrays = 'data:image/jpg;base64,' + res.response.imageContents;
  
          }  
          else{
            image_Arrays = '../../../assets/images/notfound.png';
          }
        }else{
          image_Arrays = '../../../assets/images/notfound.png';
        }
        
       const albums = {
           src: image_Arrays,
         
       };
    
        this._albums_lab.push(albums);


      
      },
      err=>{
        console.log(err)
      })
    }
   

    console.log(this._albums_lab)
  }
_albums_Pres=[];
callImage_Prescription(i:number,id:any,displayDataPrescriptions){
  this.indexid=i;
  this.displayDataPrescriptions=displayDataPrescriptions;
  this.loading = true
  let image_Arrays = '';
  this._albums_Pres.length=0;
  for(let j=0;j<displayDataPrescriptions.length;j++){

    this.service.getImageById(displayDataPrescriptions[j].id).subscribe((res: any)=>{
      console.log(res)
      this.loading = false
      this.healthRecordsLoaded = true

      if(res.response.documentType!='application/pdf'){
        if(res.response.imageContents!= null){
          image_Arrays = 'data:image/jpg;base64,' + res.response.imageContents;

        }  
        else{
          image_Arrays = '../../../assets/images/notfound.png';
        }
      }else{
        image_Arrays = '../../../assets/images/notfound.png';
      }
      
     const albums = {
         src: image_Arrays,
       
     };
  
      this._albums_Pres.push(albums);


    
    },
    err=>{
      console.log(err)
    })
  }
 

  console.log(this._albums_Pres)


}

  open(index: number): void {
    // open lightbox
    //alert(index);
    //console.log(this._albums);
    this._albums.length=0;
    for (let i = 0; i < this.oldPrescriptionLists.length; i++) {
      const src = 'data:image/jpg;base64,' + this.oldPrescriptionLists[i].imageContents;
      let caption = '';
      if(this.oldPrescriptionLists[i].fileName == null){
         caption = 'Prescription_' + i;
      }else{
        caption = this.oldPrescriptionLists[i].fileName;
      }

      const thumb = 'data:image/jpg;base64,' + this.oldPrescriptionLists[i].imageContents;
      const album = {
         src: src,
         caption: caption,
         thumb: thumb
      };

      this._albums.push(album);
    }


   // this._lightbox.open(this._albums, index);
    this._lightbox.open(this._albums, index, { wrapAround: true, showImageNumberLabel: true,alwaysShowNavOnTouchDevices: true,fitImageInViewPort:true });
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();

  }
  openmodal_box(id:number,oldPrescriptionList:any,) {
    this.indexid=id;
    //alert(this.indexid);
    //alert(this.oldPrescriptionList);
    console.log(id);
    console.log(this.oldPrescriptionList);
    this.oldPrescriptionList=oldPrescriptionList;
    //console.log({ListViews});
  }
  _albums_lightbox = [];
  openLight_box(src:any): any {
    // open lightbox
    this._albums_lightbox.length=0;
      const albums = {
      src: src,
     };
   this._albums_lightbox.push(albums);
        
    console.log(this._albums_lightbox);
    this._lightbox.open(this._albums_lightbox, 0);
  }
 
}
