import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorService } from '../../doctor.service';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-doctor-dashboard',
  templateUrl: './doctor-dashboard.component.html',
  styleUrls: ['./doctor-dashboard.component.css']
})
export class DoctorDashboardComponent implements OnInit {

  today = new Date()
  todayTab = true
  upcomingTab = false;
  historyTab = false
  appointmentsToday: any = []
  appointmentsTotalCount: any
  upcomingAppointments: any = []
  totalAppointments: any = []
  appointmentsTodayCount: any
  upcomingAppointmentsCount: any
  totalAppointmentsCount: any
  todayConsultationsCount: any
  DoctorTodayAppointmentsTime: any


  constructor(private service: UserService,
    private router: Router,
    private datePipe: DatePipe,
    private doctorService: DoctorService) { }

  ngOnInit() {
    window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(JSON.stringify({"backButtonDisabled":false,"change": false,"path":"Doctor Dashboard"}))

    this.service.userDetails = JSON.parse(localStorage.getItem('userDetails'))
    if(this.service.userDetails==null)
    this.router.navigate(['user/login'])
    console.log("notifyReact")
    this.doctorService.getDoctorTodayAppointmentsTime(this.service.userDetails.docId).subscribe((res: any) => {
      this.DoctorTodayAppointmentsTime = res.response
      console.log(res)
    },
      err => {
        console.log(err)
      })

    this.doctorService.getTotalAppointmentCount(this.service.userDetails.docId).subscribe((res: any) => {
      this.appointmentsTotalCount = res.response
      console.log(res)
    },
      err => {
        console.log(err)
      })
    
    this.doctorService.getUpcomingConsultationsCount(this.service.userDetails.docId).subscribe((res: any) => {
      this.upcomingAppointmentsCount = res.response
      console.log(res)
    },
      err => {
        console.log(err)
      })
    
    
    this.getDoctorAppointmentsList()
    // this.getMyTimingList()

  }

  getDoctorAppointmentsList() {
    this.doctorService.getDoctorAppointmentsList(this.service.userDetails.docId).subscribe((res: any) => {
      console.log(res)
      res.response.forEach(element => {
        let d = this.datePipe.transform(this.today, 'MMM dd y')
        let p = this.datePipe.transform(element.appDate, 'MMM dd y')
        if (d > p)
          this.totalAppointments.push(element)
        if ((d == p))
          this.appointmentsToday.push(element)
        else if ((p > d))
          this.upcomingAppointments.push(element)
      })
    },
      err => {
        console.log(err)
      })
  }
 /* getMyTimingList(){
    this.doctorService.getDoctorTimingList(this.service.userDetails.docId).subscribe((res: any) => {
      this.todayConsultationsCount = res.response
      console.log(res) 
    },
      err => {
        console.log(err)
      })
  } */

}
