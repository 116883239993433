import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  headers: any;
  host: string;
  postData: any;

  constructor(private http: HttpClient, private router: Router) {

        let token = localStorage.getItem('accessToken');
        console.log(token);

        this.headers= new HttpHeaders({
           "Authorization": "Bearer " + token
        })

       this.host= "/api/doctors"
  }

  public getDoctorDetails(docId: any){
    return this.http.get('/api/users/doctors/' + docId , {headers:this.headers});
  }

  public setTimeSlots(data: any) {
    console.log(data)
    return this.http.post(this.host + '/settimeslot', data, {headers:this.headers});
  }

  public getAppointmentSchedule(doctorId: string) {
    return this.http.get(this.host + '/' + doctorId + '/appointments', {headers:this.headers});
  }

  public getTotalAppointmentCount(docId: any){
    return this.http.get(this.host + '/' + docId + '/getDoctorActiveConsultationCount/', {headers:this.headers})
  }


  public deleteTimeSlot(doctorId: string, date: any, slotId: any) {
    return this.http.delete(this.host + '/' + doctorId + '/appointments/'+ slotId + '?date=' + date, {headers:this.headers});
  }

  public getDoctorAppointmentsList(doctorId: string) { //For Todays Appointments
    return this.http.get(this.host + '/' + doctorId + '/consultations', {headers:this.headers});
    // return this.http.get(this.host + '/' + doctorId + '/getDoctorTodayAppointments', {headers:this.headers});
  }
  public getDoctorTodayAppointmentsTime(docId: any){
    return this.http.get(this.host + '/' + docId + '/getDoctorTodayAppointmentsTime', {headers:this.headers})
  }

  public getTodayDoctorAppointmentsList(doctorId: string) { //For Todays Appointments
    // return this.http.get(this.host + '/' + doctorId + '/consultations', {headers:this.headers});
    return this.http.get(this.host + '/' + doctorId + '/getDoctorTodayAppointments', {headers:this.headers})
  }

  public getUpcomingDoctorAppointmentsList(doctorId: string) { //For Upcoming Appointments
    return this.http.get(this.host + '/' + doctorId + '/getDoctorUpComingAppointments', {headers:this.headers});
    // return this.http.get(this.host + '/' + doctorId + '/getDoctorTodayAppointments', {headers:this.headers});
  }

  public getDoctorHistoryAppointments(doctorId: string) { //For History
    return this.http.get(this.host + '/' + doctorId + '/getDoctorHistoryAppointments', {headers:this.headers});
    // return this.http.get(this.host + '/' + doctorId + '/getDoctorTodayAppointments', {headers:this.headers});
  }

  public getCompletedAppointments(doctorId: string) {
    return this.http.get(this.host + '/getconsultation-byDocCompleted?docId=' + doctorId, {headers:this.headers});
  }

  public updateAppointmentStatus(consultationId: any, status: any){
    console.log("dd")
    return this.http.put(this.host +  '/consultations/' + consultationId + '/' + status, null, {headers: this.headers})
  }

  public rejectAppointment(consultationId: any, status: any){
    //return this.http.put(this.host + '/cancel-consultation/' + consultationId, null, {headers: this.headers})
    return this.http.put(this.host +  '/consultations/' + consultationId + '/' + status, null, {headers: this.headers})
  }

  public cancelAppointent(consultationId: string, status: string): Observable<any> {
    return this.http.put(`${this.host}/cancelconsultations/${consultationId}/${status}`, null, {headers: this.headers});
  }

  public getAppointmentSlots(doctorId: any, date: any){
    return this.http.get( this.host + '/' + doctorId + '/timeslots/' + date, {headers:this.headers})
  }

  public getConsultationById(consultationId: any){
    return this.http.get( this.host + '/consultations/' + consultationId, {headers:this.headers})
  }

  public sendVideoLink(hwId: string, docId: string, patId: string, docName: string,
    patName: string, appDate: string, startTime: any, link: any, consultationId: any) {

      this.postData = {
        "hwId": hwId,
        "docId": docId,
        "patId": patId,
        "docName": docName,
        "patName": patName,
        "appDate": appDate,
        "startTime": startTime,
        "link": link,
        "consultationId": consultationId,
        "active": true
      }
    return this.http.post(this.host + '/notification', this.postData, {headers:this.headers});
  }

  public getDoctorProfilePic(docId: string){
    return this.http.get( '/api/users/profilePic/' + docId, {headers:this.headers, responseType: 'blob'})
  }

  public addPrescriptionAndClinicalNotes(data: any, patId: any, consultationId: any) {
    console.log(data)
    return this.http.post(this.host + '/saveclinicalnotes/' + patId + '/' + consultationId, data, {headers:this.headers});
  }

  public getOldPrescriptions(patId: any) {
    return this.http.get( 'api/patients/clinicalnotes/' + patId + '?sort=desc', {headers:this.headers});
    // return this.http.get(this.host + '/getDoctorPrescriptions/' + patId, {headers:this.headers});
  }
  public getTextprescription(contId: any) {
    return this.http.get('api/prescription/' + contId , { headers: this.headers });
  }

  public getTransactions(docId: any) {
    return this.http.get( this.host + '/getdoctortransaction/' + docId, {headers:this.headers});
  }

  public set_reminder(status:any){
    return this.http.get( this.host + '/set_reminder/' + status, {headers:this.headers});
  }

  public setMorningReminder(){
    return this.http.get( this.host + '/setmorningreminder/', {headers:this.headers});
  }

  public getTotalConsultationsCount(docId: any){
    return this.http.get(this.host + '/' + docId + '/getTotalConsultationsCount/', {headers:this.headers})
  }

  public getTodayConsultationsCount(docId: any){
    return this.http.get(this.host + '/' + docId + '/getTodayConsultationsCount/', {headers:this.headers})
  }

  public getUpcomingConsultationsCount(docId: any){
    return this.http.get(this.host + '/' + docId + '/getUpcomingConsultationsCount/', {headers:this.headers})
  }

  public getLabReports(patId: any){
    return this.http.get(this.host + '/getDoctorHealthRecords/' + patId, {headers:this.headers})
  }

  public getManualPrescriptions(patId: any){
    return this.http.get(this.host + '/getDoctorPrescriptions/' + patId, {headers:this.headers})
  }

  public getOtherRecords(patId: any){
    return this.http.get(this.host + '/getDoctorOtherRecords/' + patId, {headers:this.headers})
  }

  public getPatientList(docId: any){
    return this.http.get(this.host + '/getDoctorConsultedPateints/' + docId, {headers:this.headers})
  }

  public getPatientAppointments(patId: any){
    return this.http.get(this.host + '/getDoctorPateintsConsultations/' + patId, {headers:this.headers})
  }

  public getClinicalNotes(conId: any) {
    return this.http.get('api/doctors/getClinicalNotesConsultationId/' + conId, {headers:this.headers});
    // return this.http.get(this.host + '/getDoctorPrescriptions/' + patId, {headers:this.headers});
  }
  public getDoctorTimingList(docId: any){
    // http://localhost:9096/api/doctors/DOC-00001/consultationstime
    return this.http.get(this.host + '/' + docId + '/getTodayConsultationsCount', {headers:this.headers});

  }
  public getDoctorVideocallRoomId(conId: any) {
    return this.http.get('api/doctors/createvideolink/' + conId+'/DOCTOR/', { headers: this.headers });
  }

  public getMedicinelistforsearch(searchData: any) {
    return this.http.get('api/medicine-es/' + searchData, { headers: this.headers })
  }

  public addTextprescriptionup(data: any) {
    console.log(data)
    return this.http.post('api/prescription/', data, { headers: this.headers });
  }
}
