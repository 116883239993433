import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder, AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  error: string;

  emailId: string;
  username: string;
  password: string;
  message: any;

  showMsg: boolean = false;
  showForm: boolean = true;
  showWarn: boolean = false;


  constructor(private formBuilder: FormBuilder,
    public dialogbox: MatDialogRef<ForgotpasswordComponent>,
    private service: UserService,
    private router: Router) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      username: new FormControl('', Validators.required)
    }, { validator: this.MustMatch('password', 'confirmPassword') });

  }
  get fval() {
    return this.registerForm.controls;
  }


  Onclose() {
    this.dialogbox.close();
  }

  /* passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confirmPassword').value) {
        return {invalid: true};
    }
  } */

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  send() {

    console.log(this.username, this.password);
    this.submitted = true;
    if (this.registerForm.invalid) {
      console.log("invalid")
      return;
    }
    else {
      let resp = this.service.forgetPassword(this.username, this.password);
      console.log(this.username, this.password);
      resp.subscribe(data => {
        this.message = data;
        var str = new String(data);
        var index = str.localeCompare("ACCEPTED");
        if (index == 0) {
          alert("Password changed")
          this.showMsg = true;
          this.showForm = false;
          this.showWarn = false;
          this.dialogbox.close();
        } else {
          this.showMsg = false;
          this.showWarn = true;
          this.showForm = false;
        }
      }, error => {
        this.showMsg = false;
        this.error = error.error.message;
        this.showWarn = true;
        this.showForm = false;
      });
    }
  }

}
