import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import { AuthAuthenticationService } from '../../authentication.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ConfirmationModelComponent } from '../confirmation-model/confirmation-model.component';
import { MatDialog } from '@angular/material';
import { DoctorService } from '../../doctor.service';
import { find } from 'rxjs/operators';

export class Calendar {
  day: string;
  slots: any[];

  constructor(day: string, slots: any) {
    this.day = day
    this.slots = slots
  }
}


@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.css']
})

export class CalendarViewComponent implements OnInit {

  consultationDetails: any;
  currentDate = new Date();
  currentDay: any;
  day: any = new Date();
  daysMobileView: any = []
  timeslots: any = ["07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45",
    "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45",
    "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00",
    "18:15", "18:30", "18:45", "19:00"]
  arr1: Calendar[] = []
  arr2: any = []
  selectedDay: any;
  patientDialog = false;

  constructor(private service: UserService,
    private doctorService: DoctorService,
    private router: Router,
    private datePipe: DatePipe,
    private dialog: MatDialog) { }

  ngOnInit() {

    this.currentDay = this.datePipe.transform(this.currentDate, 'd EE')
    console.log(this.currentDay)
    this.service.userDetails = JSON.parse(localStorage.getItem('userDetails'))
   window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(JSON.stringify({"backButtonDisabled":false,"change": true,"path":"calender-view"}))
    console.log("notifyReact")
    if(this.service.userDetails==null)
    this.router.navigate(['user/login'])

    //trigger appointment schedule at backend
    this.getSchedule()

    this.getAppointmentSlotsForSelectedDate(this.day, 0)

    for (let i = 1; i < 7; i++) {
      let day = this.day.setDate(this.day.getDate() + 1)
      console.log(day)
      this.getAppointmentSlotsForSelectedDate(day, i)
    }

    //popover

   /*  (<any>$("[data-toggle=popover]")).popover({
      content: function () {
        return $("#popover-content").html();
      }
    });

    (<any>$("#popoverItem")).popover({
      content: function () {
        return $("#popover-content").html();
      }
    }); */
  }


  getSchedule() {
    this.doctorService.getAppointmentSchedule(this.service.userDetails.docId).subscribe((res: any) => {
      console.log(res)
    },
      err => {
        console.log(err)
      })
  }

  moveDaysForward() {

    this.daysMobileView = [];
    for (let i = 3; i < 6; i++) {
      this.daysMobileView.push(this.arr1[i])
    }

  }

  moveDaysBackward() {

    this.daysMobileView = [];
    for (let i = 0; i < 3; i++) {
      this.daysMobileView.push(this.arr1[i])
    }

  }

  showDialog(slot: any, day: any) {

    if (slot.patName != null && slot.patName != '') {
      this.doctorService.getConsultationById(slot.cnsltId).subscribe((res: any) => {
        console.log(res)
        let dialogRef = this.dialog.open(ConfirmationModelComponent)
        dialogRef.componentInstance.consultationDetail = res.response
      },
        err => {
          console.log(err)
        })
    }
  }


  openVideoCall(consultation: any) {

    localStorage.setItem('consultation', JSON.stringify(consultation))
    this.router.navigate(['doctor/videoCall'])

  }

  getAppointmentSlotsForSelectedDate(selectedDate: any, i: number) {

    this.arr1[i] = new Calendar(selectedDate, [])
    this.selectedDay = this.datePipe.transform(selectedDate, 'd EE')
    selectedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd')
    this.arr1[i].day = this.selectedDay
    console.log(selectedDate)
    console.log(this.service.userDetails.docId)
    this.doctorService.getAppointmentSlots(this.service.userDetails.docId, selectedDate).subscribe((res: any) => {
      console.log(res)
      let slotList = res.response
      let nullValues = {
        "subSlotId": '',
        "startTime": '',
        "endTime": '',
        "booked": false,
        "cnsltId": null,
        "patName": null,
        "hwName": null,
        "average": 15
      }
      this.timeslots.forEach(element => {
        let index = slotList.findIndex(x => x.startTime === element)
        if (index == -1)
          this.arr1[i].slots.push(nullValues)
        else
          this.arr1[i].slots.push(slotList[index])
      });
      console.log(this.arr1[i])
    },
      err => {
        console.log(err)
      })

    if (i >= 0 && i <= 2)
      this.daysMobileView.push(this.arr1[i])
  }
}
