import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthAuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  headers: HttpHeaders;
  postData = {};
  userDetails: any;
  username: String;
  password: String;
  patientId: string;
  //hwId: string;
  //consultationId: any;
  consultation: any;
  patient: any;
  doctor: any;
  //feedback: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authservice: AuthAuthenticationService) { 

      let token = localStorage.getItem('accessToken'); 
      // console.log(token);
  
      this.headers= new HttpHeaders({
         "Authorization": "Bearer " + token 
      })
      
    }

  host: string = '/api'; 


  public forgetPassword(username: string, password: string) {
    this.postData = {
      "password": password,
      "username": username,
    };
    return this.http.put(this.host + "/forgotPassword", this.postData);
  }

  public sendOtp(phNumber: number) {
    this.postData = {
      "phNumber": phNumber
    }
    return this.http.post(this.host + "/users/otp/send", this.postData)
  }

  public resendOtp(phNumber: number) {
    this.postData = {
      "phNumber": phNumber, 
      "retrytype" : "text"
     }

    return this.http.post(this.host + "/users/otp/resend", this.postData)
  }

  public verifyOtp(otp: any, phNumber: any){  
    this.postData = {
      "phNumber": phNumber,
      "otp": otp
    }
    console.log("verifying")
    return this.http.post(this.host + "/users/otp/verify", this.postData)
  }

  public getDoctorDetails(doctorId: any) {
    return this.http.get(this.host + "/getDoctorDetails/" + doctorId);
  }

  public getPatientDetails(patientId: any) {
    return this.http.get(this.host + "/getPatientDetails/" + patientId);
  }

  public getProfilePicture(id: any) {
    return this.http.get(this.host + '/getProfilePicture/' + id);
  }

  public updateProfile(shortName: string, fullName: string, gender: string, age: number, dob: Date,
    addressType: string, houseNo: string, subLoc1: string, subLoc2: string,
    district: string, state: string, pinCode: number, hospitalId: string) {
    this.postData = {
      "shortName": shortName,
      "fullName": fullName,
      "gender": gender,
      "age": age,
      "dob": dob,
      "addressType": addressType,
      "houseNo": houseNo,
      "subLoc1": subLoc1,
      "subLoc2": subLoc2,
      "district": district,
      "state": state,
      "pinCode": pinCode,
      "hospitalId": hospitalId,
      "userId": this.userDetails.id
    };
    console.log(this.postData);
    return this.http.post(this.host + "/profile", this.postData);
  }

  public getPatientHealthRecords(consultationId){
    let token = localStorage.getItem('accessToken'); 
    console.log(token);
    this.headers= new HttpHeaders({
      "Authorization": "Bearer " + token 
    })
    return this.http.get(this.host + '/users/gethealthrecord/' + consultationId , {headers:this.headers})
  }

  public getImageById(imageID:any){
    return this.http.get(this.host + '/users/getimagebyid/' + imageID , {headers:this.headers})
  }
}
