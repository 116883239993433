import { Component, OnInit } from "@angular/core";
import { DatePipe } from '@angular/common';
import { Router } from "@angular/router";
import { DoctorService } from "../../doctor.service";
import { UserService } from "../../user.service";
import { DEFAULT_PLACEHOLDER_IMG } from '../../constants/constants';

@Component({
  selector: "app-doctor-menu",
  templateUrl: "./doctor-menu.component.html",
  styleUrls: ["./doctor-menu.component.css"],
})
export class DoctorMenuComponent implements OnInit {
  profilePicBlob: any;
  dataURL: any = DEFAULT_PLACEHOLDER_IMG;
  doctorDetails: any = {};

  constructor(
    public service: UserService, 
    private router: Router,
    private datePipe: DatePipe,
    public doctorService: DoctorService
  ) {}

  ngOnInit() {
    this.service.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.getProfilePic();
    this.getDoctorDetails();
/*
    setInterval(() => {
      var time = this.datePipe.transform(new Date(), 'H:mm');
      var startHourInSec = startHourInSec = parseInt(time.substring(0,2)) * 3600;
      var startMinInSec = startMinInSec = parseInt(time.substring(3,6)) * 60;
      var startTotalSeconds = startTotalSeconds = startHourInSec + startMinInSec;
      console.log(startTotalSeconds)
      if(startTotalSeconds>=23100 && startTotalSeconds<=23700){
        this.doctorService.setMorningReminder().subscribe((res: any) => {
          console.log(res)
        },
          err => {
            console.log(err)
          })
      }
    }, 120000);

    

    setInterval(() => {
      this.doctorService
        .set_reminder("CONFIRMED")
        .subscribe(
          (res: any) => {
            console.log("remainder")
            console.log(res)
          },
          (err) => {
            console.log(err);
          }
        );
    }, 300000);*/
  }

  getProfilePic() {
    this.doctorService
      .getDoctorProfilePic(this.service.userDetails.docId)
      .subscribe((res: any) => {
        // console.log(res)
        this.profilePicBlob = res;
        //convert image blob to base64 dataURL to display in img tag
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = () => {
          this.dataURL = reader.result;
          // console.log(this.dataURL)
        };
      });
  }

  getDoctorDetails() {
    this.doctorService
      .getDoctorDetails(this.service.userDetails.docId)
      .subscribe((res: any) => {
        // console.log(res)
        this.doctorDetails = res.response;
      });
  }

  logout() {
    //clear session
    localStorage.removeItem("userDetails");
    localStorage.clear();

    //clear token
    localStorage.removeItem("accessToken");
    localStorage.clear();

    this.router.navigate(["user/login"]);
  }
}
