import { Component, OnInit } from '@angular/core';
import { HealthworkerService } from "../../healthworker/healthworker.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'app-doctor-invoice',
  templateUrl: './doctor-invoice.component.html',
  styleUrls: ['./doctor-invoice.component.css']
})
export class DoctorInvoiceComponent implements OnInit {
  showBackBtn: boolean = false;
  loading: boolean = false;
  invoice: any = [];
  enableInvoiceModal : boolean;
  public consultationId : string;

  constructor(private router: Router, private healthworkerService:HealthworkerService) {
    this.router.events.subscribe((navChange) => {
      if(navChange instanceof NavigationEnd) {
        this.showBackBtn = (navChange.urlAfterRedirects !== "/doctor/dashboard");
      }
    });
   }

  ngOnInit() {
  window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(JSON.stringify({"backButtonDisabled":false,"change": true,"path":"Doctor Invoice"}))
  this.getInvoice_dct()
  }
  goBackToPreviousPage(): void {
    window.history.back();
  }
  getInvoice_dct(){
    let consltID = localStorage.getItem("InvConsltID");
  this.healthworkerService.getInvoice(consltID).subscribe((res: any)=>{
      console.log("Result", res);
      this.invoice = res.response;
      this.loading = false;
     this.enableInvoiceModal = true;
     },
     err=>{
       console.log(err)
     });
  }
}

