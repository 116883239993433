import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthAuthenticationService {

  USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
  public userName: String;
  public passWord: String;
  user: any;
  public enableHistory: boolean;

  constructor(
    private http: HttpClient) { }

  userAuthenticate(username: String, password: String) {
    type bodyType = 'body';
    return this.http.get('/api/login',
      { headers: { 'X-Requested-With': 'XMLHttpRequest', authorization: this.createBasicAuthToken(username, password) } })
  }

  authenticate(username: String, password: String) {
    return this.http.get('/admin/adminlogin',
      { headers: { 'X-Requested-With': 'XMLHttpRequest', authorization: this.createBasicAuthToken(username, password) } })
  }

  registerSuccessfulLogin(userDetails, username, password) {
    this.user = username;
    sessionStorage.setItem('authenticatedUser', username);
    let authString = 'Basic ' + btoa(username + ':' + password);
    sessionStorage.setItem('basicauth', authString);
    sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
    //sessionStorage.setItem(this.USER_NAME_SESSION_ATTRIBUTE_NAME, username)
  }

  logOut() {
    this.sessionLogout().subscribe(res => {
    });
    sessionStorage.removeItem('authenticatedUser');
    sessionStorage.removeItem('userDetails');
    sessionStorage.clear();
    this.userName = null;
    this.passWord = null;
  }

  sessionLogout() {
    return this.http.get("/api/sessionLogout");
  }

  createBasicAuthToken(username: String, password: String) {
    return 'Basic ' + window.btoa(username + ":" + password)
  }

  isUserLoggedIn() {
    let user = sessionStorage.getItem('authenticatedUser')
    //let user = sessionStorage.getItem(this.USER_NAME_SESSION_ATTRIBUTE_NAME)
    if (user === null) return false
    return true
  }

  getLoggedInUserName() {
    let user = sessionStorage.getItem('authenticatedUser')
    //let user = sessionStorage.getItem(this.USER_NAME_SESSION_ATTRIBUTE_NAME)
    if (user === null) return ''
    return user
  }
}
