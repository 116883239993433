import { Directive } from '@angular/core';

@Directive({
  selector: '[appConfirmEqualValidator]'
})
export class ConfirmEqualValidatorDirective {

  constructor() { }

}
