import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from "@angular/material";
import { DoctorService } from "../../doctor.service";
import { NgxImageCompressService } from 'ngx-image-compress';
import { CroppedEvent } from 'ngx-photo-editor';

@Component({
  selector: 'app-upload-prescription',
  templateUrl: './upload-prescription.component.html',
  styleUrls: ['./upload-prescription.component.css']
})
export class UploadPrescriptionComponent implements OnInit {
  formData: any = new FormData();
  imageChangedEvent: any;
  base64: any;
  base64Prescription: any;

  prescriptionImage: any;

  @Input() patId: string;
  @Input() consultationId: string;
  @Input() id: string;
  @Input() showlabel: boolean = false;
  @Input() showIcon: boolean = false;
  @Input() labelValue: string;
  @Input() className: string;
  @Input() isScreenView: boolean = false;

  @Output() prescriptionUploaded = new EventEmitter<any>();

  loading: boolean = false;
  onlylabel: boolean = false;
  snackbarOptions: MatSnackBarConfig = {
    duration: 3000,
    verticalPosition: "bottom",
  };

  constructor(
    private doctorService: DoctorService,
    private snackbar: MatSnackBar,
    private imageCompress: NgxImageCompressService) { }

  ngOnInit(): void {
  }

  prescriptionUpload(event: any) {
    let file: File = event.target.files[0];
    if (file != null) {
      this.loading = true;
      const size: number = file.size / (1024 * 1024);
      if (size > 15) {
        this.showMessage("File size can't be more than 15 mb!!");
        this.loading = false;
      }
      else {
        let formData: FormData = new FormData();
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let base64Prescription: any = reader.result;


          if (file.type == 'application/pdf') {
            base64Prescription = base64Prescription.replace(/^data:application\/[a-z]+;base64,/, "")
            const blobObject = this.dataURItoBlob(base64Prescription, file.type);
            const fileObject = new File([blobObject], file.name, { type: file.type });
            formData.append('clinicalNotePic', fileObject);
            this.callPrescriptionUploadApi(formData);
          } else {
            this.imageCompress.compressFile(base64Prescription, 50, 50).then(
              result => {
                let p = result.replace(/^data:image\/[a-z]+;base64,/, "");
                const imageBlob = this.dataURItoBlob(p, 'image/png');
                const imageFile = new File([imageBlob], file.name, { type: 'image/jpg' })
                formData.append('clinicalNotePic', imageFile);
                this.callPrescriptionUploadApi(formData);
              });
          }
        }

        reader.onerror = () => {
          this.loading = false;
          this.showMessage("Enter Valid File ");
        };
      }
    }
  }

  dataURItoBlob(dataURI: any, type: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: type });
    return blob;
  }

  showMessage(message: string) {
    this.snackbar.open(message, '', this.snackbarOptions);
  }

  callPrescriptionUploadApi(formData: FormData) {
    this.doctorService.addPrescriptionAndClinicalNotes(formData, this.patId, this.consultationId).subscribe((res: any) => {
      this.loading = false;
      if (res.responseCode == "201") {
        if (this.prescriptionUploaded.observers.length > 0) {
          this.prescriptionUploaded.emit({
            updated: true
          });
        }
        this.showMessage("Prescription Submitted!!!");
      }
    },
      err => {
        this.loading = false;
        this.showMessage("Exception Occour During Prescription Upload!!!");
      });

  }
  fileChangeEvent(event: any) {
    console.log(event);

    this.imageChangedEvent = event;
    //this.imageChangedEvent = event.target.files[0];
    //this.showPopup = true;
  }

  imageCropped(e: CroppedEvent) {
    console.log(e);
    this.patId = this.patId;
    this.prescriptionImage = e.file;
    let size = e.file.size  / (1024 * 1024)
    console.log(this.prescriptionImage)

    if(this.prescriptionImage!=null){
      if(size>15)
          this.snackbar.open("File size can't be more than 15 mb!!", '',{
            duration: 3000,
            verticalPosition: 'bottom'
          })
      else{
        console.log("1");
        this.convertToBase64(this.prescriptionImage,this.consultationId)
      }
    }


    //this.showPopup = false;
    //this.base64 = event.base64;
    //this.imageChangedEvent = '';
  }

  convertToBase64(file: File, consultationId: any){
    console.log(2);
    console.log(file);
    const reader: FileReader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = ()=> {
      this.base64Prescription = reader.result
      this.submitNewPrescriptionAndClinicalNotes(consultationId, 0)
    }
  }

  submitNewPrescriptionAndClinicalNotes(consultationId: any, index: number) {
    console.log(3);

    this.loading = true
    this.formData = new FormData()
    let p = this.base64Prescription.replace(/^data:image\/[a-z]+;base64,/, "")
    console.log('Size before compression' + this.prescriptionImage.size)
    /*this.imageCompress.compressFile(this.base64Prescription, 50, 50).then(
      result => {
        console.warn('Size in bytes is now:', this.imageCompress.byteCount(result))
        let p = result.replace(/^data:image\/[a-z]+;base64,/, "")
        const imageBlob = this.dataURItoBlob(p)
        const imageFile = new File([imageBlob], this.prescriptionImage.name, { type: 'image/jpg' })
        this.formData.append('clinicalNotePic', imageFile)
        this.callPrescriptionUploadApi(consultationId, index)
    })*/

    const imageBlob = this.dataURItoBlob(p,'image/jpg')
    const imageFile = new File([imageBlob], this.prescriptionImage.name, { type: 'image/jpg' })
    this.formData.append('clinicalNotePic', imageFile)
    this.callPrescriptionUploadApi(this.formData)

  }


}
