import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router'
import { UserService } from '../../user.service';
import { AuthAuthenticationService } from '../../authentication.service';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  loginForm: FormGroup;
  submitted = false;
  phNumber: number;
  error = '';
  getOTP = false;
  response: any;
  otp: any;
  otpConfig = {
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: true,
    inputStyles: {
      'width': '37px',
      'height': '35px',
      'font-size': '17px',
    },
    allowNumbersOnly: true
  }
  loading = false;
  phoneNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  userDetails: any;
  snackbarOptions: any = {
    duration: 4000,
    verticalPosition: 'top',
    horizontalPosition: 'right'
  }

  @ViewChild('ngOtpInput', {static: false}) otpInputRef:any;
  
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private service: UserService,
    private spinnerService: Ng4LoadingSpinnerService,
    private dialog: MatDialog,
    private router: Router, private Authservice: AuthAuthenticationService,
    private snackbar: MatSnackBar) { }
  ngAfterViewInit(): void {
    console.log("Login Components ngAfterViewInit");
  }

  ngOnInit() {
    console.log("Login Components ngOnInit");
    // window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(JSON.stringify({"backButtonDisabled":false,"change": false,"path":"Login component"}))
    // console.log("notifyReact")
    
    this.service.userDetails = JSON.parse(localStorage.getItem('userDetails'))
    
    if(this.service.userDetails==null){
       this.router.navigate(['user/login'])
    }
    else{
      if(this.service.userDetails.role=="RMP")
        this.router.navigate(['healthworker/dashboard'])
      else if(this.service.userDetails.role=="DOCTOR")
        this.router.navigate(['doctor/dashboard'])
    }

    
    this.loginForm = this.formBuilder.group({
      phNumber: new FormControl('', [Validators.required, Validators.pattern(this.phoneNumberPattern)]),
    });

  }

  get fval() {
    return this.loginForm.controls;
  }


  login() {
    localStorage.setItem("defaultLang", "Telugu");
    this.submitted = true;
    if (this.loginForm.invalid) {
      console.log("invalid")
      this.snackbar.open('Please enter a valid mobile number', '', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      return;
    } else {
      this.loading = true;
      this.service.sendOtp(this.phNumber).subscribe(data => {
        this.response = data;
        console.log(data)
        if (this.response.message == "OTP sent to registered mobile number") {
          this.getOTP = true; 
          this.loading = false;
          window.addEventListener('message', (data: any)=>{
            console.log(data)
            let jsonData = JSON.parse(data.data)
            this.otp = jsonData.otp
            this.otpInputRef.setValue(this.otp)
            console.log(this.otp)
          })
        } else {
          this.loading = false;
          this.snackbar.open(this.response.message, '', this.snackbarOptions);
        }
      },
        error => {
          this.loading = false;
          console.log(error);
          this.error = error.statusText;
          this.snackbar.open(this.error, '', this.snackbarOptions);
        });
    }

  }

  onOtpChange(otp) {
    this.otp = otp;
    console.log(this.otp)
  }

  verifyOtp() {
    console.log("INSIDE LOGIN ")
    this.service.verifyOtp(this.otp, this.phNumber).subscribe((res: any) => {
      console.log(res)
      console.log(res.responseCode)

      if (res.responseCode == "200") {
        localStorage.setItem('accessToken', res.response.token)
        this.userDetails = res.response.data;
        localStorage.setItem('userDetails', JSON.stringify(this.userDetails));

        if (this.userDetails.role == "DOCTOR")
          this.router.navigate(['doctor/dashboard'])

        else if (this.userDetails.role == "RMP")
          this.router.navigate(['healthworker/dashboard'])
      }
      else {
        console.log(res.message);
        this.snackbar.open(res.message, '', this.snackbarOptions)
      }
    },
      err => {
        this.snackbar.open(err.statusText, '', this.snackbarOptions);
        console.log(err)
      })
  }

  resendOtp() {
    if (this.loginForm.invalid) {
      console.log("invalid")
      this.snackbar.open('Please enter a valid mobile number', '', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      return;
    } else {
      this.loading = true;
      this.service.resendOtp(this.phNumber).subscribe(data => {
        this.response = data;
        //this.spinnerService.hide();
        this.loading = false;
        console.log(data)
        if (this.response.responseCode = "200") {
          this.getOTP = true;
          this.snackbar.open("OTP resent", '', this.snackbarOptions);
        } else {
          this.snackbar.open(this.response.message, '', this.snackbarOptions);
        }
      },
        error => {
          this.loading = false;
          console.log(error);
          this.error = error.statusText;
          this.snackbar.open(this.error, '', this.snackbarOptions);
        });
    }
  }
}
