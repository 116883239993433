import { Component, EventEmitter, Inject, OnInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface ProfileImageData {
  base64ImageUrl: string;
}
@Component({
  selector: 'app-crop-profile-pic',
  templateUrl: './crop-profile-pic.component.html',
  styleUrls: ['./crop-profile-pic.component.css']
})
export class CropProfilePicComponent implements OnInit {
  croppedImage: string = '';
  cropperReady: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CropProfilePicComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProfileImageData
  ) { }

  ngOnInit(): void {
  }

  imageCropped(image: string): void {
    this.croppedImage = image;
  }
  imageLoaded(): void {
    this.cropperReady = true;
  }

  loadImageFailed(): void {
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  cropConfirm(): void {
    let params = {
      "imageUrl": this.croppedImage
    }
    this.dialogRef.close(params);

  }


}
