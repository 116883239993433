import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { DoctorService } from "../../doctor.service";



@Component({
  selector: 'app-cancel-appointment',
  templateUrl: './cancel-appointment.component.html',
  styleUrls: ['./cancel-appointment.component.css']
})
export class CancelAppointmentComponent implements OnInit {

  snackbarOptions: MatSnackBarConfig = {
    duration: 3000,
    verticalPosition: "bottom",
  };

  @Input() consultationId:string;
  @Input() index:number;
  @Output() appoinmentCancel = new EventEmitter<any>();


  constructor(private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private doctorService: DoctorService) { }

  ngOnInit(): void {
  }

  cancelAppointment(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {  msg: "Are you sure you want to cancel the Appointment ?" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'true') {
              this.appoinmentCancel.emit({"index":this.index,"consultationId":this.consultationId});   
      }
    });
  }

}
