import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppadminService } from '../../appadmin.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-rpp-transactions',
  templateUrl: './rpp-transactions.component.html',
  styleUrls: ['./rpp-transactions.component.css']
})
export class RPPTransactionsComponent implements OnInit {
  isrmpTransactionListLoading: boolean = false;
  isSpecialityShown: boolean; // hidden by default
  dtOptions: DataTables.Settings = {};
  flg: boolean = false;
  rmpTransactionList = [];
  snackbarOptions: any = {
    duration: 3000,
    verticalPosition: 'bottom'
  };
  Speciality() {
    this.isSpecialityShown = !this.isSpecialityShown;
  }
  status: string;
  IsmodelShow: boolean = false;

  constructor(private appadmin: AppadminService, private router: Router, private snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      searching:false,
      serverSide: true,
      ordering:false,
      ajax: (dataTablesParameters: any, callback) => {
        this.isrmpTransactionListLoading = true;
        let expectedPage = dataTablesParameters.start/dataTablesParameters.length;
        this.appadmin.getTransactionList('rmp', expectedPage+1,dataTablesParameters.length).subscribe((data: any) => {
          this.isrmpTransactionListLoading = false;
          this.rmpTransactionList = data.response
 
          callback({
            recordsTotal: Number.parseInt(data.totalcount),
            recordsFiltered: Number.parseInt(data.totalcount),
            data: []
          });

        },
          error => {
            this.isrmpTransactionListLoading = false;
          });
      }

    } ;
    //this.gettransactions();
  }

  gettransactions() {
    this.isrmpTransactionListLoading = true;
    this.appadmin.getRppTransactins().subscribe((data:any)=> {
      this.isrmpTransactionListLoading = false;
      if (data) {
        console.log('getTransactions', data);
        this.flg = true;
        this.rmpTransactionList = data.response
      } else {
        this.snackBar.open(data.message, '', this.snackbarOptions);
      }
    },
      error => {
        console.log(error);
      });
  }

  onchecked(e, item) {
    if(item.rmpstatus == 'paid'){
      const dialogRef = this.dialog.open(confirmationRmppopComponent,
        {
          width: '396px',
          data: item
        }).afterClosed().subscribe(res => { 
          if (res == true) {
            let index = this.rmpTransactionList.findIndex(x=> x.consultationId == item.consultationId)
            this.rmpTransactionList[index].rmpstatus = 'notpaid'
            e.target.checked = false;
          console.log('rmpTransactionList', this.rmpTransactionList)
        }
       if(res == undefined){
          let index = this.rmpTransactionList.findIndex(x=> x.consultationId == item.consultationId)
          this.rmpTransactionList[index].rmpstatus == 'paid'
          e.target.checked = true;
          console.log('rmpTransactionList', this.rmpTransactionList)
        }
      });
     } else {
      let body = {
        consultationId: item.consultationId,
        status: 'paid',
        reason : { reason : ''},
        person : 'RMP'
      }
      this.appadmin.TransactionStatus(body).subscribe((x:any)=>{
        if(x.responseCode==201){
          this.snackBar.open(x.message, '', this.snackbarOptions);
          let index = this.rmpTransactionList.findIndex(x=> x.consultationId == item.consultationId)
          this.rmpTransactionList[index].rmpstatus = 'paid'
          e.target.checked = true;
        console.log('rmpTransactionList', this.rmpTransactionList)
        }
      },error=>{
        console.log(error)
      })
     }

   }

}

@Component({
  selector: ' confirmation-rmp-pop',
  templateUrl: './confirmation-rmp-pop.html',
})
export class confirmationRmppopComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<confirmationRmppopComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private snackBar: MatSnackBar, 
    private appadmin: AppadminService,
    private fb: FormBuilder,
    private router: Router){}
    ischecked:boolean=false;
    snackbarOptions: any = {
      duration: 3000,
      verticalPosition: 'bottom'
      };

      reasonForm:FormGroup;

      initilizeReasonForm(){
        this.reasonForm = this.fb.group({
           reason: ['', [Validators.required]]
        })
      }
      get f() { 
        return this.reasonForm.controls; 
      }

      changeDctrTransStatus(){
        if(!this.reasonForm.valid){
          this.validateAllFormFields(this.reasonForm)
        }else{
          let body = {
            consultationId: this.data.consultationId,
            status: 'notpaid',
            reason : { reason : this.reasonForm.get('reason').value},
            person : 'RMP'
          }
          this.appadmin.TransactionStatus(body).subscribe((x:any)=>{
            if(x.responseCode==201){
              this.dialogRef.close(true);
              this.snackBar.open(x.message, '', this.snackbarOptions);
            }else{
              this.snackBar.open(x.message, '', this.snackbarOptions);
            }
          },error=>{
            console.log(error)
          })
  
        }
      }

      validateAllFormFields(formGroup: any) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

  ngOnInit() {
    console.log(this.data)
    this.initilizeReasonForm();
  }

}
