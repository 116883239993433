import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MagicCallResponse } from '../models/magic.call';

@Injectable({
  providedIn: 'root'
})
export class HealthworkerService {

  headers: any;
  host: string;
  doctorHost: string;
  postData: any;
  notifications: any;
  badgeCount: number;
  notificationInterval: any;
  orderhost: string;
  languageSelected: any;
  consultationId: any;
  consultationDetails: any;
  healthRecords: any;
  doctorFee: any;
  rmpCharges: any;
  platformFee: any;

  constructor(
    private http: HttpClient,
    private router: Router) {

    let token = localStorage.getItem('accessToken');
    // console.log(token);

    this.headers = new HttpHeaders({
      "Authorization": "Bearer " + token
    })

    this.host = "/api/healthworkers"
    this.orderhost = "/api/telehealth/paytmOrder"
    this.doctorHost = "/api/doctors";
  }

  private _refreshNeeded$ = new Subject<void>();

  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  public goBackToPreviousPage(): void {
    window.history.back();
  }

  public registerPatient(patientDetails: any) {
    return this.http.post(this.host + '/patients', patientDetails, { headers: this.headers })
  }

  public patientProfile(patientDetails: any) {
    return this.http.put(this.host + '/patients/profile', patientDetails, { headers: this.headers })
  }

  public updatePatientProfile(patId: any, patientDetails: any) {
    return this.http.put(this.host + '/patients/updateprofile/' + patId, patientDetails, { headers: this.headers })
  }

  public sendOtpToPatient(mobileNum: any) {
    this.postData = {
      "phNumber": mobileNum
    }
    return this.http.post(this.host + "/otp-send", this.postData, { headers: this.headers })
  }

  public verifyPatientCreationOtp(otp: any, phNumber: any) {
    this.postData = {
      "phNumber": phNumber,
      "otp": otp
    }
    console.log("verifying")
    return this.http.post(this.host + "/otp-verify", this.postData, { headers: this.headers })
  }

  public resendOtpToPatient(mobileNum: any) {
    this.postData = {
      "phNumber": mobileNum,
      "retrytype": "text"
    }
    return this.http.post(this.host + "/otp-resend/", this.postData, { headers: this.headers })
  }

  public getPatientList(hwId: any) {
    return this.http.get(this.host + '/' + hwId + '/patients', { headers: this.headers })
  }

  public getSinglePatientDetails(hwId: any, patId: any) {
    return this.http.get(this.host + '/' + hwId + '/patients/' + patId, { headers: this.headers })
  }

  public getPatientProfileDetails(patId: any, language: string) {
    return this.http.get(this.host + '/patients/profile/' + language + '/' + patId, { headers: this.headers })
  }

  public deletePatient(patientId: any, hwId: any) {
    return this.http.delete(this.host + '/' + hwId + '/patients/' + patientId, { headers: this.headers });
  }

  public getHealthworkerBookedAppointments(hwId: any) {
    return this.http.get(this.host + '/' + hwId + '/consultations', { headers: this.headers })
  }

  public sendVideoLinkToPatient(patId: any, consultationId: any) {
    return this.http.get(this.host + '/sendVideoLink/' + patId +'/' + consultationId,  { headers: this.headers })
  }

  public getOtherBookedAppointments(hwId: any) {
    return this.http.get(this.host + '/' + hwId + '/othersconsultations', { headers: this.headers })
  }

  public getDoctorList() {
    return this.http.get(this.host + '/doctors', { headers: this.headers })
  }

  public bookAppointment(data: any) {
    console.log(data)
    return this.http.post(this.host + "/consultations", data, { headers: this.headers })
  }

  public getNotifications(hwId: string) {
    return this.http.get(this.host + '/' + hwId + '/notification', { headers: this.headers })
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }

  public clearOneNotification(notificationId: string) {
    return this.http.delete(this.host + '/' + notificationId + '/notification/clear', { headers: this.headers })
  }

  public clearAllNotifications(hwId: string) {
    return this.http.delete(this.host + '/' + hwId + '/notification/clearAll', { headers: this.headers })
  }

  public markNotificationAsRead(notificationId: string) {
    return this.http.put(this.host + '/' + notificationId + '/notification/read', null, { headers: this.headers })
  }

  public patientIdProofUpload(document: any, hwId: string, patId: string) {
    const formData = new FormData();

    formData.append('file', document);
    return this.http.post(this.host + '/idProofUpload/' + hwId + '/' + patId, formData, { headers: this.headers })
  }

  public getPatientIdProof(patId: string) {
    return this.http.get('/api/users/patIdProof/' + patId, { headers: this.headers, responseType: 'blob' })
  }

  public getProfilePic(id: string) {
    return this.http.get('/api/users/profilePic/' + id, { headers: this.headers, responseType: 'blob' })
  }

  public getPatientAppointments(patId: any) {
    return this.http.get(this.host + '/patients/appointments/' + patId, { headers: this.headers })
  }

  public getPatientPrescriptions(patId: any) {
    return this.http.get('api/patients/clinicalnotes/' + patId + '?sort=asc', { headers: this.headers })
  }

  public updateQuestionnaire(data: any, patId: string) {
    return this.http.post(this.host + '/' + patId + '/patients/questions', data, { headers: this.headers })
  }

  public changeStatustoWaiting(doctorPhNumber: any, patName: string) {
    return this.http.get(this.host + '/wait?phNumber=' + doctorPhNumber + '&patName=' + patName, { headers: this.headers })
  }

  public getENSurveyQuestions() {
    return this.http.get(this.host + '/ReadENQuestions', { headers: this.headers })
  }

  public getTLSurveyQuestions() {
    return this.http.get(this.host + '/ReadTLQuestions', { headers: this.headers })
  }

  public gethwIdProof(hwId: string) {
    return this.http.get('/api/users/hwIdProof/' + hwId, { headers: this.headers, responseType: 'blob' })
  }

  public gethwCertificate(hwId: string) {
    return this.http.get('/api/users/hwCert/' + hwId, { headers: this.headers, responseType: 'blob' })
  }

  paytmSubmit(id: any, doctorFee: any, rmpCharges: any, platformFee: any) {

    let postData = {
      "id": id,
      "doctorFee": doctorFee,
      "rmpCharges": rmpCharges,
      "platformFee": platformFee
    }
    return this.http.post(this.orderhost, postData, { headers: this.headers })
  }

  public uploadPatientHealthRecords(consultationId: any, formData: any) {
    return this.http.put(this.host + '/consultations/' + consultationId, formData, { headers: this.headers })
  }

  public uploadPatientProfilePic(patId: any, rmpId: any, formData: any) {
    return this.http.put(this.host + '/patients/profilepic/' + patId + '/' + rmpId, formData, { headers: this.headers })
  }

  public uploadPatientIdProof(patId: any, rmpId: any, formData: any) {
    return this.http.put(this.host + '/patients/idproof/' + patId + '/' + rmpId, formData, { headers: this.headers })
  }

  public uploadDocuments(formData: any) {
    return this.http.post(this.host + '/uploadHealthRecord', formData, { headers: this.headers })
  }

  public getuploadedDocuments(patId: any) {
    return this.http.get(this.host + '/getHealthRecordbyPatId/' + patId, { headers: this.headers })
  }

  public uploadPrescriptions(formData: any) {
    return this.http.post(this.host + '/uploadPrescriptions', formData, { headers: this.headers })
  }

  public uploadOtherRecords(formData: any) {
    return this.http.post(this.host + '/uploadOtherRecords', formData, { headers: this.headers })
  }

  public updateDocuments(data: any) {
    return this.http.put(this.host + '/updateHealthRecord', data, { headers: this.headers })
  }

  public updatePrescription(data: any) {
    return this.http.put(this.host + '/updatePrescriptionRecord', data, { headers: this.headers })
  }

  public updateOtherDocuments(data: any) {
    return this.http.put(this.host + '/updateOtherRecord', data, { headers: this.headers })
  }

  public getuploadedPrescriptions(patId: any) {
    return this.http.get(this.host + '/getPrescriptionbyPatId/' + patId, { headers: this.headers })
  }

  public getOtherDocuments(patId: any) {
    return this.http.get(this.host + '/getOtherRecordsbyPatId/' + patId, { headers: this.headers })
  }

  public getHwDetails(hwId: string) {
    return this.http.get('/api/users/healthworkers/' + hwId, { headers: this.headers })
  }

  public getInvoice(consultationId: string) {
    return this.http.get('/api/users/getinvoice/' + consultationId, { headers: this.headers })
  }

  public getHwTransactions(hwId: string) {
    return this.http.get(this.host + '/getrmptransaction/' + hwId, { headers: this.headers })
  }

  public deleteHealthRecord(documentId: any) {
    return this.http.delete('api/users/deleteImage/' + documentId, { headers: this.headers });
  }

  public getTransactionId(cnstId: string) {
    return this.http.get(this.host + '/getTransactionId/' + cnstId, { headers: this.headers })
  }

  public getNotificationsByStatus(hwId: string) {
    return this.http.get(this.host + '/' + hwId + '/notification/INPROGRESS', { headers: this.headers })
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }

  public deleteHealthRecordsInBulk(docType: string, patId: string): Observable<any> {
    return this.http.delete(`${this.host}/deleterecords/${patId}/${docType}`, { headers: this.headers });
  }

  public deleteHealthRecordImage(docType: string, docId: string): Observable<any> {
    let delEndpoint: string;
    if (docType === 'labReport') {
      delEndpoint = 'deleteHealthRecordImage';
    } else if (docType === 'prescription') {
      delEndpoint = 'deletePrescriptionImage';
    } else if (docType === 'others') {
      delEndpoint = 'deleteOtherRecordsImage';
    }
    return this.http.delete(`/api/users/${delEndpoint}/${docId}`, { headers: this.headers });
  }

  public getPatientIdProofs(patId: any) {
    return this.http.get(this.host + '/patidProof/' + patId, { headers: this.headers })
  }

  public getDoctorAssistantPhNumber(consltId: string): Observable<any> {
    return this.http.get(this.doctorHost + '/getconsultationdetail/' + consltId, { headers: this.headers });
  }

  public initiateMagicCallApi(url: string): Observable<MagicCallResponse> {
    return this.http.get<MagicCallResponse>(url);
  }
  public getRMPVideocallRoomId(conId: any) {
    return this.http.get('/api/doctors/createvideolink/' + conId + '/RMP', { headers: this.headers });
  }

  public getDocumentList(documentType: string, patId: string, pageNumber: number, pageContainCount: number) {
    let endPointUrl: string = '';
    if (documentType === 'prescription') {
      endPointUrl = '/getPrescriptionListByPatId/';
    } else if (documentType === 'labReport') {
      endPointUrl = '/getHealthRecordListByPatId/';
    } else {
      endPointUrl = '/getOtherRecordsListByPatId/';
    }

    pageNumber = pageNumber - 1;
    const url: string = this.host + `${endPointUrl}` + `${patId}` + "/" + `${pageNumber}` + "/" + `${pageContainCount}`;

    return this.http.get(url, { headers: this.headers })
  }

  public updateFile(documentType:string, data: any) {
    let endPointUrl: string = '';
    if (documentType === 'prescription') {
      endPointUrl = '/updatePrescriptionRecord/';
    } else if (documentType === 'labReport') {
      endPointUrl = '/updateHealthRecord/';
    } else {
      endPointUrl = '/updateOtherRecord/';
    }
    return this.http.put(this.host + endPointUrl, data, { headers: this.headers })
  }

  getClinicalNotesByConsIdAndPatId(consId,patId){
    return this.http.get(this.host +"/get_clinical_notes/" + consId + '/'+patId, { headers: this.headers });
  }
  rmpcancellationappointment(consultationId:any, status: any){
    return this.http.put('api/doctors/cancelconsultations/' + consultationId + '/' + 'RMP' , null, {headers: this.headers});
  }

  public bookWithoutPayment(data: any) {
    return this.http.post(this.host + '/nopaymentconsultations', data, { headers: this.headers })
  }

  public uploadvitals(patid:any, data: any) {
    console.log("vitals",data);
    return this.http.post('api/vitals/' + patid, data, { headers: this.headers })
  }

  public getvitals(patId:any,page:number,limit:number) {
    return this.http.get('/api/vitals/' + patId+'/'+page+'/'+limit, { headers: this.headers });
  }

  public deletevitals(vtId: any) {
    return this.http.delete('/api/vitals/' + vtId, { headers: this.headers });
  }
}
