import { Component, OnInit } from '@angular/core';
import { DoctorService } from '../../doctor.service';
import { UserService } from '../../user.service';
import { ActivatedRoute } from '@angular/router';
import { HealthworkerService } from "../../healthworker/healthworker.service";
import { Lightbox, LightboxConfig, LightboxEvent, LIGHTBOX_EVENT, IEvent, IAlbum } from 'ngx-lightbox';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-prescription-page',
  templateUrl: './prescription-page.component.html',
  styleUrls: ['./prescription-page.component.css']
})
export class PrescriptionPageComponent implements OnInit {

  _albums = [];
  indexid: number;
 // ListViews: any[];
  oldPrescriptionListView:any=[]
  enableModal : boolean = false;
  oldPrescriptionsLoaded:boolean;
  loading:boolean;
  showOldPrescriptions:boolean;
  imageArray:any;
  oldPrescriptionList:any=[];
  patId:string;
  consultationId:string;
  textPrescriptions: any = []
  drugname: any;
  quantity: any;
  dosage: any;
  intaketm: any;
  instructions: any;
  noOfDays: number;
  constructor(private _lightbox: Lightbox, private doctorService: DoctorService, private snackbar: MatSnackBar, private service: UserService, private route: ActivatedRoute, private healthworkerService:HealthworkerService) {

   }

  ngOnInit(): void {
    window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(JSON.stringify({"backButtonDisabled":false,"change": true,"path":"Patient Priscription"}))

    this.patId = this.service.patient.patId;
    this.consultationId = this.service.patient.consultationId;

this.getOldPrescriptionList();
this.enableModal = false;
  }

  checkIfOldPrescriptionsLoaded(){
    if(!this.oldPrescriptionsLoaded) {
      this.loading = true;
      this.showOldPrescriptions = true;
    }else{
      this.showOldPrescriptions = true;
    }
  }

  prescriptionUploaded(event:any){
    if(event.updated){
      this.loading=true;
      this.getOldPrescriptionList();
    }
  }

  getOldPrescriptionList(){
    this.healthworkerService.getClinicalNotesByConsIdAndPatId(this.consultationId,this.patId).subscribe((res: any)=>{
         console.log(res)
         this.oldPrescriptionList = res.response
         this.oldPrescriptionsLoaded = true;
         if(this.oldPrescriptionList != null){

          for (let i = 0; i < this.oldPrescriptionList.length; i++) {
            const src = 'data:image/jpg;base64,' + this.oldPrescriptionList[i].imageContents;
            let caption = '';
            if(this.oldPrescriptionList[i].fileName == null){
               caption = 'Prescription_' + i;
            }else{
              caption = this.oldPrescriptionList[i].fileName;
            }

            const thumb = 'data:image/jpg;base64,' + this.oldPrescriptionList[i].imageContents;
            const album = {
               src: src,
               caption: caption,
               thumb: thumb
            };

            this._albums.push(album);
          }

        }
         this.loading = false;
         this.enableModal = true;
      },
      err=>{
        console.log(err)
      })

    this.doctorService.getTextprescription(this.service.patient.conId).subscribe((res: any) => {
      console.log("Prescription Text:");
      console.log(res);
      this.textPrescriptions = res;
    },
      err => {
        console.log(err)
      })
  }


  keyword = 'medicine';
  medicineData = [];
  medicineSelect(item) {
    console.log("1");
  }

  getMedicineName(val: string) {
    this.doctorService.getMedicinelistforsearch(val).subscribe((res: any) => {
      this.medicineData = res;
      // console.log(this.medicineData);
    });
  }

  medFocused(e) {
    // console.log("3");
  }


  addPrescriptionList() {
    let consultationData = JSON.parse(localStorage.getItem("consultation"));
    if (this.drugname == null) {
      this.snackbar.open("Enter Medicine/Drug Name", "", {
        duration: 3000,
        verticalPosition: "bottom",
      });
      return;
    } else if (this.drugname.medicine == null) {
      this.snackbar.open("Enter Medicine/Drug Name", "", {
        duration: 3000,
        verticalPosition: "bottom",
      });
      return;
    } else if (this.quantity == null) {
      this.snackbar.open("Enter Quantity", "", {
        duration: 3000,
        verticalPosition: "bottom",
      });
      return;
    } else if (this.dosage == null) {
      this.snackbar.open("Enter Dosage", "", {
        duration: 3000,
        verticalPosition: "bottom",
      });
      return;
    }
    else if (this.intaketm == null) {
      this.snackbar.open("Select Intake Time", "", {
        duration: 3000,
        verticalPosition: "bottom",
      });
      return;
    } else if (this.noOfDays == null) {
      this.snackbar.open("Enter No. of Days", "", {
        duration: 3000,
        verticalPosition: "bottom",
      });
      return;
    }

    let objs = {
      "consultationId": this.service.patient.conId,
      "medicine": this.drugname.medicine,
      "description": this.instructions,
      "quantity": this.quantity,
      "dosage": this.dosage,
      "intaketime": this.intaketm,
      "noOfDays": this.noOfDays
    }

    this.doctorService.addTextprescriptionup(objs).subscribe((res: any) => {
      console.log(res);
      if (res[0].date) {

        this.snackbar.open("Prescription added Successfully", "", {
          duration: 3000,
          verticalPosition: "bottom",
        });
        this.getOldPrescriptionList();
        this.drugname = null;
        this.instructions = null;
        this.quantity = null;
        this.dosage = null;
        this.intaketm = null;
        this.noOfDays = null;
      }
    })

  }


  callImage(id:any,i : any){
    this.loading = true
    if(localStorage.getItem(id) != null && localStorage.getItem(id) != ""){
      this.loading = false;
      this.imageArray = localStorage.getItem(id);
      this.oldPrescriptionListView = [];

      this.oldPrescriptionListView.push(this.oldPrescriptionList[i]);
      console.log(this.oldPrescriptionListView);
      const src = 'data:image/jpg;base64,' + this.oldPrescriptionList[i].imageContents;
            let caption = '';
            if(this.oldPrescriptionList[i].fileName == null){
               caption = 'Prescription_' + i;
            }else{
              caption = this.oldPrescriptionList[i].fileName;
            }

            const thumb = 'data:image/jpg;base64,' + this.oldPrescriptionList[i].imageContents;
            this._albums = [];
            const album = {
               src: src,
               caption: caption,
               thumb: thumb
            };

            this._albums.push(album);
      return;
    }
    this.service.getImageById(id).subscribe((res: any)=>{
      console.log(res)
      this.loading = false;
      this.oldPrescriptionListView = [];
      const pics = {
        fileName: res.response.documentFile,
        fileType: res.response.documentType,
        id: res.response.id,
        imageContents : res.response.imageContents
     };
      this.oldPrescriptionListView.push(pics);
      console.log(this.oldPrescriptionListView);
      const src = 'data:image/jpg;base64,' + res.response.imageContents;
            let caption = '';
            if(res.response.fileName == null){
               caption = 'Prescription_' + i;
            }else{
              caption = res.response.fileName;
            }

            const thumb = 'data:image/jpg;base64,' + res.response.imageContents;
            this._albums = [];
            const album = {
               src: src,
               caption: caption,
               thumb: thumb
            };

            this._albums.push(album);
      if(res.response.documentType!='application/pdf')
        this.imageArray='data:image/png;base64,' + res.response.imageContents;

      else
        this.imageArray='data:application/pdf;base64,' + res.response.imageContents;

    },
    err=>{
      console.log(err)
    })
  }

  goBackToPreviousPage(): void {
    window.history.back();
  }
  open(index: number): void {
    // open lightbox
    console.log(index);
    this._albums.length=0;

    for (let i = 0; i < this.oldPrescriptionList.length; i++) {
      const src = 'data:image/jpg;base64,' + this.oldPrescriptionList[i].imageContents;
      let caption = '';
      if(this.oldPrescriptionList[i].fileName == null){
         caption = 'Prescription_' + i;
      }else{
        caption = this.oldPrescriptionList[i].fileName;
      }

      const thumb = 'data:image/jpg;base64,' + this.oldPrescriptionList[i].imageContents;
      const album = {
         src: src,
         caption: caption,
         thumb: thumb
      };

      this._albums.push(album);
    }
   // this._lightbox.open(this._albums, index);
    this._lightbox.open(this._albums, index, { wrapAround: true, showImageNumberLabel: true,alwaysShowNavOnTouchDevices: true,fitImageInViewPort:true });

  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  closePrescription():void{
    this.enableModal = false;
  }
  openmodal_box(id:number,oldPrescriptionList:any) {
    this.indexid=id;
    //alert(this.oldPrescriptionList);
    console.log(id);
    console.log(this.oldPrescriptionList);
    /*this.ListViews = [];
     ListViews = oldPrescriptionList.slice();
     this.ListViews=ListViews;
    var valueAtIndex1 = oldPrescriptionList[id];
    console.log(valueAtIndex1);
    ListViews.splice(id, 1);
    console.log(ListViews.slice());
    ListViews.splice(0, 0, valueAtIndex1);
    console.log(ListViews.slice());
    this.ListViews=ListViews.slice();*/
    this.oldPrescriptionList=oldPrescriptionList;
    //console.log({ListViews});
  }

}
