import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
 
 
import { Observable,Subscription, interval  } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NotifyhealthworkerService } from '../../notifyhealthworker.service';


@Component({
  selector: 'app-confirmation-model',
  templateUrl: './confirmation-model.component.html',
  styleUrls: ['./confirmation-model.component.css']
})
export class ConfirmationModelComponent implements OnInit {

  isTimeStarted:boolean =false;
  consultationDetail: any;
  private updateSubscription: Subscription; 
  constructor(private router: Router,private datePipe: DatePipe,private notifyhealthworkerService:NotifyhealthworkerService) { }

  ngOnInit() {
    this.updateSubscription = interval(1000).subscribe((val) => { 

      var appointmentDate =  appointmentDate = this.datePipe.transform(this.consultationDetail.appDate, 'yyyy-MM-dd');

      var startHourInSec = startHourInSec = parseInt(this.consultationDetail.startTime.substring(0,2)) * 3600;
      var startMinInSec = startMinInSec = parseInt(this.consultationDetail.startTime.substring(3,6)) * 60;
      var startTotalSeconds = startTotalSeconds = startHourInSec + startMinInSec;

      var endHourInSec = endHourInSec = parseInt(this.consultationDetail.endTime.substring(0,2)) * 3600;
      var endMinInSec = endMinInSec = parseInt(this.consultationDetail.endTime.substring(3,6)) * 60;
      var endTotalSeconds =  endTotalSeconds =  endHourInSec + endMinInSec;

      var appDuration = appDuration = endTotalSeconds - startTotalSeconds  ;;
      var appTotalSeconds;

      var systemDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');      
      var sysHourInSec = new Date().getHours() * 3600;
      var sysMinInSec = new Date().getMinutes() * 60;
      var sysTotalSeconds ;

      sysTotalSeconds = sysHourInSec + sysMinInSec;
      appTotalSeconds = startHourInSec + startMinInSec;

      if(appointmentDate === systemDate && sysTotalSeconds >= startTotalSeconds - appDuration && sysTotalSeconds <= endTotalSeconds + appDuration){
        this.isTimeStarted = true;
        console.log('true')
      }else{
        console.log('false')
       this.isTimeStarted = false;
       this.updateSubscription.unsubscribe();
      }
    })
  }

  openVideoCall(consultation: any){   
    localStorage.setItem('consultation', JSON.stringify(consultation))
   // this.notifyhealthworkerService.notifyhealthworker(consultation);
    this.router.navigate(['doctor/videoCall'])
    window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(JSON.stringify({"backButtonDisabled":false,"callStarted": true,"path":"confirmtion-modal"}))
    console.log("notifyReact")
    this.router.navigate(['doctor/videoCall']) 
   }
}
