import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import { DoctorService } from '../../doctor.service';
import { MatSnackBar } from "@angular/material";
import { NgxImageCompressService } from 'ngx-image-compress';
import { Router } from "@angular/router";

@Component({
  selector: 'app-doctor-consultation-history',
  templateUrl: './doctor-consultation-history.component.html',
  styleUrls: ['./doctor-consultation-history.component.css']
})
export class DoctorConsultationHistoryComponent implements OnInit {

  prescriptionsToShow: any = [];
  enableModal : boolean = false;
  ItemsArray: any = []
  prescriptionPictureUpload: boolean = false
  prescriptionCaptured: boolean = false

  public patId: string;
  prescriptionImage: any;
  base64Prescription: any;
  formData: any = new FormData();
  snackbarOptions: any = {
    duration: 3000,
    verticalPosition: "bottom",
  };
  loading: boolean = false;

  constructor(
    public service: UserService, public doctorService: DoctorService,
    private snackbar: MatSnackBar,
    private imageCompress: NgxImageCompressService,
    private router: Router) { }

  ngOnInit() {
    window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(JSON.stringify({"backButtonDisabled":false,"change": false,"path":"Doctor Consultation History"}))
    this.enableModal = false;
    this.service.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    console.log("history")
    console.log(this.service.userDetails)
    this.getCompletedAppointments()
  }

  getCompletedAppointments() {
    this.loading = true
    this.doctorService.getDoctorHistoryAppointments(this.service.userDetails.docId).subscribe((res: any) => {
      // console.log(res)
      this.loading = false
      this.ItemsArray = res.response
      console.log(this.ItemsArray)
    },
      err => {
        console.log(err)
      })
  }

  prescriptionUpload(e, index, consultationId,patId){
    this.patId = patId;
    this.prescriptionImage = e.target.files[0]
    let size = e.target.files[0].size / (1024 * 1024)
    console.log(this.prescriptionImage)
    if(this.prescriptionImage!=null){
      if(size>15)
          this.snackbar.open("File size can't be more than 15 mb!!", '',{
            duration: 3000,
            verticalPosition: 'bottom'
          })
      else{
        this.convertToBase64(this.prescriptionImage,consultationId, index)
      }
    }
  }

  convertToBase64(file: File, consultationId: any, index: number){
    const reader: FileReader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = ()=> {
      this.base64Prescription = reader.result
      this.submitNewPrescriptionAndClinicalNotes(consultationId, index)
    }
  }


  submitNewPrescriptionAndClinicalNotes(consultationId: any, index: number) {
    this.loading = true
    this.formData = new FormData()
    this.base64Prescription.replace(/^data:image\/[a-z]+;base64,/, "")
    console.log('Size before compression' + this.prescriptionImage.size)
    this.imageCompress.compressFile(this.base64Prescription, 50, 50).then(
      result => {
        console.warn('Size in bytes is now:', this.imageCompress.byteCount(result))
        let p = result.replace(/^data:image\/[a-z]+;base64,/, "")
        const imageBlob = this.dataURItoBlob(p)
        const imageFile = new File([imageBlob], this.prescriptionImage.name, { type: 'image/jpg' })
        this.formData.append('clinicalNotePic', imageFile)
        this.callPrescriptionUploadApi(consultationId, index)
    })

  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }


  callPrescriptionUploadApi(consultationId: any, index: number){
    this.doctorService.addPrescriptionAndClinicalNotes(this.formData, this.patId, consultationId).subscribe((res: any) => {
      console.log(res)
      this.loading = false
      if (res.responseCode == "201") {
        //this.oldPrescriptionList.push(res.response)
        // this.getOldPrescriptionList()
        this.prescriptionPictureUpload = true
        this.prescriptionCaptured = false
        this.formData = new FormData()
        this.snackbar.open("Prescription Submitted!!!", '', {
          duration: 3000,
          verticalPosition: 'bottom'
        })
        //this.ItemsArray.remove(index);
        //this.displayData.remove(index);
        this.getCompletedAppointments()
      }
    },
      err => {
        this.loading = false
        console.log(err)
      })
  }

  showPrescription(data : any[]):void{
    this.prescriptionsToShow = data;
    this.enableModal = true;
  }
  closePrescription():void{
    this.enableModal = false;
  }

  openHealthRecored(consultation: any) {
    localStorage.setItem("consultation", JSON.stringify(consultation));
    this.service.patient = consultation;
    this.router.navigate(["doctor/healthRecords"]);
    window["ReactNativeWebView"] &&
      window["ReactNativeWebView"].postMessage(
        JSON.stringify({  "backButtonDisabled": false,"change": true,"path":"openPatientProfile" })
      );
  }

}
