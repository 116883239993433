import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PullToRefreshService } from "@piumaz/pull-to-refresh";
import { Observable, fromEvent, merge, of } from "rxjs";
import { ConnectionService } from 'ng-connection-service';
import { MatSnackBar } from '@angular/material'
//import * as PullToRefresh from 'pulltorefreshjs';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "tele-consultation";
  snackbarOptions: any = {
    verticalPosition: 'bottom'
  }

  // online$: Observable<boolean>;
  name: any;

  isConnected = true;
  noInternetConnection: boolean;
  snakbarObject:any=null;
  //ptrinit:any = PullToRefresh.init();
  //ptr = PullToRefresh.init({
   // mainElement: 'body',
   // distThreshold: 80,
    //onRefresh() {
      //window.location.reload();
   // }
  //});


  nonRefreshablePages: string[] = [
    '/doctor/videoCall',
    'doctor/jitsiMeet',
    '/healthworker/videoCall'
  ];

  constructor(private pullToRefreshService: PullToRefreshService, private router: Router, private connectionService: ConnectionService, private snackbar: MatSnackBar
    ) {

    // if(navigator.onLine){
    //   this.noInternetConnection=false;
    // }else{
    //   this.noInternetConnection=true;
    // }
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;

      if (this.isConnected) {
        this.noInternetConnection=false;
        if(this.snakbarObject){
          this.snakbarObject.dismiss();
        }
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
      }
      else {
        this.noInternetConnection=true;
        this.snakbarObject = this.snackbar.open("Something went wrong or Please check the internet connection.", 'close', this.snackbarOptions)

      }
    });

    let _router = this.router;
    
    this.pullToRefreshService.refresh$().subscribe(() => {
      console.log('refresh by observable '+this.router.url);
      if(this.isRefreshablePage() && !this.isModalOpen()) {
          let currentUrl: string[] = this.router.url.split('/');
          currentUrl = currentUrl.slice(1);
          console.log(currentUrl);
          let modalBackdrops = document.getElementsByClassName('modal-backdrop');
          if(modalBackdrops && modalBackdrops.length > 0) {
            for(let i=0; i<modalBackdrops.length; i++) {
              modalBackdrops[i].remove();
            }
          }
          // _router.navigateByUrl("/");
          // currentUrl = currentUrl.substring(1, currentUrl.length);
          // this.router.navigate([currentUrl]);
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
              this.router.navigate(currentUrl);
          });
      }
    });

    // this.online$ = merge(
    //   of(navigator.onLine),
    //   fromEvent(window, "online").pipe(mapTo(true)),
    //   fromEvent(window, "offline").pipe(mapTo(false))
    // );
    // this.networkStatus();
  }
  ngOnInit(): void {

  }

  public isRefreshablePage(): boolean {
    return (this.nonRefreshablePages.indexOf(this.router.url) < 0);
  }

  public isModalOpen(): boolean {
    let modalElems = document.getElementsByClassName('modal');
        // console.log(modalElems);

    let isModalOpen: boolean = false;
    if(modalElems && modalElems.length > 0) {
      for(let i=0; i<modalElems.length; i++) {
        if(modalElems[i]['className'].indexOf('show') >= 0) {
          isModalOpen = true;
          break;
        }
      }
    }
    return isModalOpen;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    console.log('Back button pressed');
    let modalBackdrops = document.getElementsByClassName('modal-backdrop');
    if(modalBackdrops && modalBackdrops.length > 0) {
      for(let i=0; i<modalBackdrops.length; i++) {
        modalBackdrops[i].remove();
      }
    }
  }

  // public networkStatus() {
  //   this.online$.subscribe((value) => {
  //     this.name = value;
  //   });
  //}

}
