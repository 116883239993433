import { Component, OnInit, EventEmitter, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Appointment } from "../../models/appointment";
import { UserService } from "../../user.service";
import { DatePipe } from "@angular/common";
import { MatDialogConfig, MatDialog, MatDatepickerInputEvent, MatSnackBar } from "@angular/material";

import { ToastrService } from "ngx-toastr";
import { DoctorService } from "../../doctor.service";
import { transform } from "typescript";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-add-appointment-schedule",
  templateUrl: "./add-appointment-schedule.component.html",
  styleUrls: ["./add-appointment-schedule.component.scss"],
})
export class AddAppointmentScheduleComponent implements OnInit {
  panelOpenState = false;
  appointment: any = [];
  appointmentSlot: Appointment;
  schedule: any = [];
  showBackBtn: boolean = false;
  deletedSlotDate: any;
  averageTimeList: number[] = [5, 10, 15, 30];
  today = new Date();
  todaysDate = new Date();
  currentTime: any;
  dayOfWeek: any;
  timeSlots: any = [
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
  ];

  appointmentSchedule: boolean = true;

  snackbarOptions: any = {
    duration: 3000,
    verticalPosition: "bottom",
  };

  constructor(
    private formBuilder: FormBuilder,
    private service: UserService,
    public doctorService: DoctorService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.router.events.subscribe((navChange) => {
      if(navChange instanceof NavigationEnd) {
        this.showBackBtn = (navChange.urlAfterRedirects !== "/doctor/dashboard");
      }
    });
  }

  getDayOfWeek() {
    var gsDayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    var d = new Date();
    return gsDayNames[d.getDay()];
  }



  ngOnInit() {
    window["ReactNativeWebView"] &&
      window["ReactNativeWebView"].postMessage(
        JSON.stringify({ "change": true,"backButtonDisabled":false,"path":"Add Appointment Schedule" })
      );

    var hours = this.addZero(this.today.getHours());
    var minutes = this.today.getMinutes();
    var day = this.today.getDate();
    this.currentTime = hours + ":" + minutes;
    
    this.dayOfWeek = this.getDayOfWeek();

    console.log(this.currentTime);

    this.service.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (this.service.userDetails == null) this.router.navigate(["user/login"]);
    
    console.log("notifyReact");
    this.initialiseAppointment();
    this.getSchedule();
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  initialiseAppointment() {
    this.appointmentSlot = {
      docId: this.service.userDetails.docId,
      date: new Date(),
      averageTime: 15,
      startTime: "",
      endTime: "",
    };
  }

  filterBookedstartTime(t:any,appday:any,slots:any){
    //console.log(t, appday, slots);
    let res=false ;
    if ((t < this.currentTime && appday === this.dayOfWeek)) {
      res = true;                
    }
     else {
      for(var i=0; i<slots.length;i++){
        if (t > slots[i].startTime && t < slots[i].endTime) {
          //console.log(t, slots[i].startTime, t, slots[i].endTime);
          res = true;
        } else {
          res = false;
        }
      }
                 
    } 
    return res;   
  }

  filterBookedEndTime(t: any, appTimeFull: any, slots: any) {
    //console.log(t, appday, slots);
    let res = false;
    if ((t < appTimeFull)) {
      res = true;
    }
    else {
      for (var i = 0; i < slots.length; i++) {
        if (t > slots[i].startTime && t < slots[i].endTime) {
          //console.log(t, slots[i].startTime, t, slots[i].endTime);
          res = true;
        } else {
          res = false;
        }
      }

    }
    return res;
  }

  deleteSlot(date: string, item: any) {
    /* this.schedule.forEach(element => {
      if (element.day == day) {
        this.deletedSlotDate = new Date(element.date)
      }
    }) */

    this.deletedSlotDate = this.datePipe.transform(date, "yyyy-MM-dd");
    console.log(this.deletedSlotDate);
    this.doctorService
      .deleteTimeSlot(
        this.service.userDetails.docId,
        this.deletedSlotDate,
        item.slotUid
      )
      .subscribe(
        (res: any) => {
          console.log(res);
          if (res.message == "Appointment Slot deleted.") {
            this.snackBar.open(res.message, "", this.snackbarOptions);
            this.schedule = res.response.appointmentDays;
          } else this.snackBar.open(res.message, "", this.snackbarOptions);
        },
        (err) => {
          console.log(err);
          this.snackBar.open(err.error.message, "", this.snackbarOptions);
        }
      );
  }

  getSchedule() {
    this.doctorService
      .getAppointmentSchedule(this.service.userDetails.docId)
      .subscribe(
        (res: any) => {
          this.schedule = res.response;
          console.log(this.schedule);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  submitTimeSlot(date: any) {
    /* this.schedule.forEach(element => {
      if (element.day == this.appointmentSlot.day)
        this.appointmentSlot.date = new Date(element.date)
    }) */

    this.appointmentSlot.date = date;
    console.log(this.appointmentSlot);

    if (
      this.appointmentSlot.startTime == "" ||
      this.appointmentSlot.endTime == ""
    ) {
      this.snackBar.open(
        "Start time / End Time cannot be empty!!",
        "",
        this.snackbarOptions
      );
      return;
    } else {
      this.doctorService.setTimeSlots(this.appointmentSlot).subscribe(
        (res: any) => {
          console.log(res);

          if (res.message == "New Slot added.") {
            this.schedule = res.response.appointmentDays;
            this.initialiseAppointment();
            //this.panelOpenState = true;
            this.snackBar.open(res.message, "", this.snackbarOptions);
          } else {
            this.snackBar.open(res.message, "", this.snackbarOptions);
            this.initialiseAppointment();
          }
        },
        (err) => {
          console.log(err);
          this.snackBar.open(
            this.appointmentSlot.startTime +
              "-" +
              this.appointmentSlot.endTime +
              " " +
              err.error.message,
            "",
            this.snackbarOptions
          );
        }
      );
    }
  }

  /* 

  setDay(day: any) {
    this.appointmentSlot.day = day;
    this.schedule.forEach(element => {
      if (element.day == this.appointmentSlot.day)
        this.appointmentSlot.date = new Date(element.date)
    })
  }

  setTime(e) {

    if (e.target.checked)
      this.selectedTimes.push(e.target.value)

    else {
      let remove: any = this.selectedTimes.indexOf(e.target.value)
      if (remove !== -1)
        this.selectedTimes.splice(remove, 1);
    }

    console.log(this.selectedTimes)
  }


  async submitTime() {

    this.appointmentSlot.startTime = ''
    this.appointmentSlot.endTime = ''
    //create all starttime and endtime lists 
    this.startTime = [];
    this.endTime = [];
    if (this.selectedTimes.length == 0)
      return;

    this.selectedTimes.forEach(element => {
      this.startTime.push(element.slice(0, 5))
      this.endTime.push(element.slice(6, 11))
    });

    for (let i = 0; i < this.startTime.length; i++) {
      let element = this.startTime[i]

      for (let j = 0; j < this.endTime.length; j++) {
        let item = this.endTime[j]
        if (element == item) {
          //merge both times
          this.startTime.splice(i, 1)
          this.endTime.splice(j, 1, this.endTime[i])
          this.endTime.splice(i, 1)
          console.log(this.startTime)
          console.log(this.endTime)
          i--;
          break;
        }
      }
    }

    console.log(this.startTime)
    console.log(this.endTime)

    //send each timeslot to backend one by one
    let k = 0;
    for(let k= 0; k< this.startTime.length; k++){

    await new Promise(resolve => {
      this.appointmentSlot.startTime = this.startTime[k];
      this.appointmentSlot.endTime = this.endTime[k]
      console.log(this.appointmentSlot.startTime)
      console.log(this.appointmentSlot.endTime)
      this.service.addAppointmentSlot(this.appointmentSlot).subscribe(res => {
        this.schedule = res;
        console.log(res)
        this.toastr.success("Time Slots added!") 
        resolve();
      },
        err => {
          console.log(err)
          this.toastr.warning(this.appointmentSlot.startTime + '-' + this.appointmentSlot.endTime + ' ' + err.error.message)
        })
      })

    }
    this.selectedTimes = []
  } */
  goBackToPreviousPage(): void {
    window.history.back();
  }
}
