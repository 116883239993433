import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
//import  * as data from  './proxy.config.json';
declare var require: any;

var SockJs = require("sockjs-client");
var Stomp = require("stompjs");


@Injectable({
  providedIn: 'root'
})
export class WebSocketServiceService {

  constructor() { }

   public connect() {
   
      console.log('environemnt url:'+environment.sockerurl)
    let socket = new SockJs(environment.sockerurl);

    let stompClient = Stomp.over(socket);

    return stompClient;
      
}
}