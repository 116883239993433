import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { MatPaginatorModule } from '@angular/material';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminRestApiService } from './admin-rest-api.service';
import { UserService } from './user.service';
import { PaymentService } from './payment.service';
import { AuthAuthenticationService } from './authentication.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BasicAuthInterceptorService } from "./basic-auth-interceptor.service";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSliderModule } from '@angular/material/slider';
import { ConfirmEqualValidatorDirective } from './confirm-equal-validator.directive';
import { WebSocketServiceService } from "./web-socket-service.service";
import { CovidService } from './covid.service';
import { DatePipe } from '@angular/common';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { NgxLoadingModule } from 'ngx-loading';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { AddAppointmentScheduleComponent } from './components/add-appointment-schedule/add-appointment-schedule.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AgmCoreModule } from '@agm/core';
import { ToastrModule } from 'ngx-toastr';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DocSearchPipe } from './healthworker/docsearch.pipe';
import { DoctorConsultationHistoryComponent } from './components/doctor-consultation-history/doctor-consultation-history.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfirmationModelComponent } from './components/confirmation-model/confirmation-model.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { DoctorDashboardComponent } from './components/doctor-dashboard/doctor-dashboard.component';
import { DoctorMenuComponent } from './components/doctor-menu/doctor-menu.component';
import { CalendarViewComponent } from './components/calendar-view/calendar-view.component';
import {PopoverModule} from 'ngx-popover'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VideoCallComponent } from './components/video-call/video-call.component';
import { AdminLoginComponent } from './components/adminlogin/adminlogin.component';
import { DataTablesModule } from 'angular-datatables/src/angular-datatables.module';
import { CurrentDayAppointmentsComponent } from './components/current-day-appointments/current-day-appointments.component';
import { UpcomingAppointmentsComponent } from './components/upcoming-appointments/upcoming-appointments.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { CheckoutModule } from 'paytm-blink-checkout-angular';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { JitsiVideoComponent } from './components/jitsi-video/jitsi-video.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import {NgxImageCompressService} from 'ngx-image-compress';
import { confirmationpopComponent } from './admin-module/doctor-transactions/doctor-transactions.component';
import { confirmationRmppopComponent } from './admin-module/rpp-transactions/rpp-transactions.component';

import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PatientListComponent } from './components/patient-list/patient-list.component';
import { HealthRecordsComponent } from './components/health-records/health-records.component';
import { ViewAppointmentsComponent } from './components/view-appointments/view-appointments.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { ConnectionServiceModule } from 'ng-connection-service';
import { PullToRefreshModule, PullToRefreshService } from '@piumaz/pull-to-refresh';
import { CropProfilePicComponent } from './components/crop-profile-pic/crop-profile-pic.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { UploadPrescriptionComponent } from './components/upload-prescription/upload-prescription.component';
import { CancelAppointmentComponent } from './components/cancel-appointment/cancel-appointment.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { LightboxModule } from 'ngx-lightbox';
import { PrescriptionPageComponent } from './components/prescription-page/prescription-page.component';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { DoctorInvoiceComponent } from './components/doctor-invoice/doctor-invoice.component';



export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmEqualValidatorDirective,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    AddAppointmentScheduleComponent,
    DoctorConsultationHistoryComponent,
    ConfirmationModelComponent,
    DoctorDashboardComponent,
    CurrentDayAppointmentsComponent,
    UpcomingAppointmentsComponent,
    DoctorMenuComponent,
    CalendarViewComponent,
    VideoCallComponent,
    AdminLoginComponent,
    ForgotpasswordComponent,
    JitsiVideoComponent,
    TransactionsComponent,
    PrivacypolicyComponent,
    confirmationpopComponent,
    confirmationRmppopComponent,
    PatientListComponent,
    HealthRecordsComponent,
    ViewAppointmentsComponent,
    AlertDialogComponent,
    DocSearchPipe,
    CropProfilePicComponent,
    ImageCropperComponent,
    UploadPrescriptionComponent,
    CancelAppointmentComponent,
    ConfirmDialogComponent, PrescriptionPageComponent, DoctorInvoiceComponent],
  imports: [
    NgxPhotoEditorModule,
    AutocompleteLibModule,
    LightboxModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatCardModule,
    MatToolbarModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    BrowserModule,
    ReactiveFormsModule,
    MatSliderModule, ScrollingModule,
    MatPaginatorModule,
    AmazingTimePickerModule,
    Ng2ImgMaxModule,
    NgxLoadingModule.forRoot({}),
    PinchZoomModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    Ng2SearchPipeModule,
    Ng4LoadingSpinnerModule.forRoot(),
    MatSnackBarModule,
    PdfViewerModule,
    CheckoutModule,
    MatListModule,
    MatDividerModule,
    MatSlideToggleModule,
    ConnectionServiceModule,
    PullToRefreshModule,

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCsrZlqfjvB0cpkT30M3BnI4weICbnZ7Yo'
      //libraries: ['places']
    }),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    NgOtpInputModule,
    PopoverModule,
    NgbModule,
    DataTablesModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [NgxImageCompressService, DatePipe, AdminRestApiService, UserService, AuthAuthenticationService, WebSocketServiceService, CovidService, {
    provide: HTTP_INTERCEPTORS,
    useClass: BasicAuthInterceptorService,
    multi: true
  }, PullToRefreshService],
  bootstrap: [AppComponent,],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    ConfirmationModelComponent, confirmationpopComponent, confirmationRmppopComponent,AlertDialogComponent,CropProfilePicComponent, ConfirmDialogComponent],
    exports: [
      HeaderComponent,
      FooterComponent
    ]
})
export class AppModule { }
