import { Component, OnInit } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DoctorService } from '../../doctor.service';
import { UserService } from '../../user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HealthworkerService } from '../../healthworker/healthworker.service';
import { DEFAULT_PLACEHOLDER_IMG } from '../../constants/constants';
import { Lightbox } from 'ngx-lightbox';
@Component({
  selector: 'app-health-records',
  templateUrl: './health-records.component.html',
  styleUrls: ['./health-records.component.css']
})
export class HealthRecordsComponent implements OnInit {

  patientDetails: any = {};
  height: any = {};
  patientIdProofBase64: any;
  base64ProfilePic: any = DEFAULT_PLACEHOLDER_IMG;
  showBackBtn: boolean = false;
  medicalHistory: any = []
  healthProfile: any = []
  activeTab: any = 'tab-1'
  enableModal : boolean = false;
  labReportTab=true;
  prescriptionTab=false;
  otherRecordsTab=false;
  healthRecords: any = []
  manualPrescriptions: any = []
  OtherRecords: any = []
  base64HealthRecords: any = []
  base64ManualPrescriptions: any = []
  base64OtherRecords: any = []
  healthRecordsLoaded = false
  loading: boolean = false
  imageArray:any;
  

  public pagingStartIndex: number;
  public pagingEndIndex: number;
  public pageSize: number;
  public numberOfPages: number;
  public pageNum: number[];
  public currentPage: number;
  public displayData: any;

  public prescPagingStartIndex: number;
  public prescPagingEndIndex: number;
  public prescPageSize: number;
  public prescNumberOfPages: number;
  public prescPageNum: number[];
  public prescCurrentPage: number;
  public displayDataPrescriptions: any;

  public orPagingStartIndex: number;
  public orPagingEndIndex: number;
  public orPageSize: number;
  public orNumberOfPages: number;
  public orPageNum: number[];
  public orCurrentPage: number;
  public displayDataOther: any;
  indexid: number;
  public medicalHistoryQuestion:string[]=['Previous / chronic health issues',
'Hospitalized in last 12 months', 'Lab Reports from last 12 months', 'Food / Medicine allergy',
'Currently using medicines'];

  constructor(public service: UserService,
    public doctorService: DoctorService,
    public healthworkerService: HealthworkerService,
    private router: Router,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    public _d: DomSanitizer,
    private _lightbox: Lightbox,
    public translate: TranslateService) {

      this.pagingStartIndex = 0;
      this.displayData = [];
      this.pageNum = [];
      this.pageSize = 10; //default page size
      this.currentPage = 1;

      this.prescPagingStartIndex = 0;
      this.displayDataPrescriptions = [];
      this.prescPageNum = [];
      this.prescPageSize = 10; //default page size
      this.prescCurrentPage = 1;

      this.orPagingStartIndex = 0;
      this.displayDataOther = [];
      this.orPageNum = [];
      this.orPageSize = 10; //default page size
      this.orCurrentPage = 1;

      this.router.events.subscribe((navChange) => {
        if(navChange instanceof NavigationEnd) {
          this.showBackBtn = (navChange.urlAfterRedirects !== "/doctor/dashboard");
        }
      });
    }

  ngOnInit() {
    window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(JSON.stringify({"backButtonDisabled":false,"change": true,"path":"doctor-health-records"}))
    this.service.userDetails = JSON.parse(localStorage.getItem('userDetails'))
    this.getPatientDetails()
    this.getPatientHealthRecords()
    this.getPatientPrescriptions()
    this.getPatientOtherRecords()
    this.enableModal = false;
  }
  changeActiveTab(value: any){
    this.activeTab = value
    console.log(this.activeTab)
    if(this.activeTab=='tab-3' && this.healthRecordsLoaded==false)
    this.loading= true
  }

  getPatientDetails(){
    this.healthworkerService.getPatientProfileDetails(this.service.patient.patId, 'ENG').subscribe((res: any)=>{
      console.log(res)
      this.patientDetails = res.response
      this.height = this.patientDetails.height
      if(this.patientDetails.patientIDProofEntity) this.patientIdProofBase64 = 'data:image/png;base64,' + this.patientDetails.patientIDProofEntity.imageContents
      if(this.patientDetails.profilePictureEntity) this.base64ProfilePic = 'data:'+this.patientDetails.profilePictureEntity.fileType+';base64,' + this.patientDetails.profilePictureEntity.imageContents

      console.log(this.base64ProfilePic);
      if(this.patientDetails.previousMedicalResponseModel!=null)
      this.medicalHistory = this.patientDetails.previousMedicalResponseModel.previousMedicalDetailsModels
      if(this.patientDetails.healthProfileResponseModel!=null)
      this.healthProfile = this.patientDetails.healthProfileResponseModel.healthProfileDetailsModel
    },
   err=>{
     console.log(err)
   })
  }

  getPatientHealthRecords(){
    this.doctorService.getLabReports(this.service.patient.patId).subscribe((res: any)=>{
      console.log(res)
      this.loading = false
      this.healthRecordsLoaded = true
      this.healthRecords = res.response
      // this.healthRecords.forEach(element => {
      //   if(element.fileType!='application/pdf')
      //   this.base64HealthRecords.push('data:image/png;base64,' + element.imageContents)

      //   else
      //   this.base64HealthRecords.push('data:application/pdf;base64,' + element.imageContents)
      // });
      this.displayPageHR(1);
      this.enableModal = true;
    },
    err=>{
      console.log(err)
    })
  }

  //for manually uploaded prescriptions
  getPatientPrescriptions(){
    this.doctorService.getManualPrescriptions(this.service.patient.patId).subscribe((res: any)=>{
      console.log(res)
      this.loading = false
      this.healthRecordsLoaded = true
      this.manualPrescriptions = res.response
      // this.manualPrescriptions.forEach(element => {
      //   if(element.fileType!='application/pdf')
      //   this.base64ManualPrescriptions.push('data:image/png;base64,' + element.imageContents)

      //   else
      //   this.base64ManualPrescriptions.push('data:application/pdf;base64,' + element.imageContents)
      // });
      this.displayPagePrescriptions(1);
    },
    err=>{
      console.log(err)
    })
  }

  //for manually uploaded other records
  getPatientOtherRecords(){
    this.doctorService.getOtherRecords(this.service.patient.patId).subscribe((res: any)=>{
      console.log(res)
      this.loading = false
      this.healthRecordsLoaded = true
      this.OtherRecords = res.response
      // this.OtherRecords.forEach(element => {
      //   if(element.fileType!='application/pdf')
      //   this.base64OtherRecords.push('data:image/png;base64,' + element.imageContents)

      //   else
      //   this.base64OtherRecords.push('data:application/pdf;base64,' + element.imageContents)
      // });
      this.displayPageOR(1);
    },
    err=>{
      console.log(err)
    })
  }

  public displayPageHR(pageNumber: number) {
    console.log(this.pageSize)
    this.pagingStartIndex = this.pageSize * (pageNumber - 1);
    this.pagingEndIndex =
      Number.parseInt(this.pagingStartIndex.toString()) +
      Number.parseInt(this.pageSize.toString()) -
      1;
    this.displayData = [];
    this.pageNum = [];
    let mod: number = this.healthRecords.length % this.pageSize;
    if (mod > 0)
      this.numberOfPages =
        Math.floor(this.healthRecords.length / this.pageSize) + 1;
    else this.numberOfPages = this.healthRecords.length / this.pageSize;

    console.log("Numberofpages" + this.numberOfPages);

    for (let i = 0; i < this.numberOfPages; i++) {
      this.pageNum.push(i + 1);
    }

    //update the data to be displayed based on above changes
    for (let i = this.pagingStartIndex; i <= this.pagingEndIndex; i++) {
      if (this.healthRecords[i] != null) {
        this.displayData.push(this.healthRecords[i]);
      }
    }

    //update current page
    this.currentPage = pageNumber;
  }

  public displayPagePrescriptions(pageNumber: number) {
    this.prescPagingStartIndex = this.prescPageSize * (pageNumber - 1);
    this.prescPagingEndIndex =
      Number.parseInt(this.prescPagingStartIndex.toString()) +
      Number.parseInt(this.prescPageSize.toString()) -
      1;
    this.displayDataPrescriptions = [];
    this.prescPageNum = [];
    let mod: number = this.manualPrescriptions.length % this.prescPageSize;
    if (mod > 0)
      this.prescNumberOfPages =
        Math.floor(this.manualPrescriptions.length / this.prescPageSize) + 1;
    else this.prescNumberOfPages = this.manualPrescriptions.length / this.prescPageSize;

    console.log("NumberOfPages" + this.prescNumberOfPages);

    for (let i = 0; i < this.prescNumberOfPages; i++) {
      this.prescPageNum.push(i + 1);
    }

    //update the data to be displayed based on above changes
    for (let i = this.prescPagingStartIndex; i <= this.prescPagingEndIndex; i++) {
      if (this.manualPrescriptions[i] != null) {
        this.displayDataPrescriptions.push(this.manualPrescriptions[i]);
      }
    }

    //update current page
    this.prescCurrentPage = pageNumber;
  }

  public displayPageOR(pageNumber: number) {
    this.pagingStartIndex = this.pageSize * (pageNumber - 1);
    this.pagingEndIndex =
      Number.parseInt(this.pagingStartIndex.toString()) +
      Number.parseInt(this.pageSize.toString()) -
      1;
    this.displayDataOther = [];
    this.pageNum = [];
    let mod: number = this.OtherRecords.length % this.pageSize;
    if (mod > 0)
      this.numberOfPages =
        Math.floor(this.OtherRecords.length / this.pageSize) + 1;
    else this.numberOfPages = this.OtherRecords.length / this.pageSize;

    console.log("Numberofpages" + this.numberOfPages);

    for (let i = 0; i < this.numberOfPages; i++) {
      this.pageNum.push(i + 1);
    }

    //update the data to be displayed based on above changes
    for (let i = this.pagingStartIndex; i <= this.pagingEndIndex; i++) {
      if (this.OtherRecords[i] != null) {
        this.displayDataOther.push(this.OtherRecords[i]);
      }
    }

    //update current page
    this.currentPage = pageNumber;
  }

  Arr_alb=[];
  getimgarray_Lab(index: number): void {
    // open lightbox
    //alert(index);
    //console.log(this._albums);
    this.Arr_alb.length=0;
    for (let i = 0; i < this._albums_lab.length; i++) {
      const src =  this._albums_lab[i].src;
         
      const album = {
         src: src,
         
      };

      this.Arr_alb.push(album);
    }
   // this._lightbox.open(this._albums, index);
    this._lightbox.open(this.Arr_alb, index, { wrapAround: true, showImageNumberLabel: true,alwaysShowNavOnTouchDevices: true,fitImageInViewPort:true });
  }
 
Arr_Pres=[];
getimgarray_Prescription(index: number): void {
  this.Arr_Pres.length=0;
  for (let i = 0; i < this._albums_Pres.length; i++) {
    const src =  this._albums_Pres[i].src;
      const album = {
       src: src,
      
    };
    this.Arr_Pres.push(album);
  }
  this._lightbox.open(this.Arr_Pres, index, { wrapAround: true, showImageNumberLabel: true,alwaysShowNavOnTouchDevices: true,fitImageInViewPort:true });
}

Arr_other=[];
getimgarray_other(index: number): void {
  this.Arr_other.length=0;
  for (let i = 0; i < this._albums_othr.length; i++) {
    const src =  this._albums_othr[i].src;
    const album = {
       src: src,
    };
    this.Arr_other.push(album);
  }
  this._lightbox.open(this.Arr_other, index, { wrapAround: true, showImageNumberLabel: true,alwaysShowNavOnTouchDevices: true,fitImageInViewPort:true });

}

_albums_othr=[];
callImage_other(i:number,id:any,displayDataOther){
  this.indexid=i;
  this.displayDataOther=displayDataOther;
  this.loading = true
  let image_Arrays = '';
  this._albums_othr.length=0;
  for(let j=0;j<displayDataOther.length;j++){
    this.service.getImageById(displayDataOther[j].id).subscribe((res: any)=>{
      console.log(res)
      this.loading = false
      this.healthRecordsLoaded = true
      if(res.response.documentType!='application/pdf'){
        if(res.response.imageContents!= null){
          image_Arrays = 'data:image/jpg;base64,' + res.response.imageContents;
        }  
        else{
          image_Arrays = '../../../assets/images/notfound.png';
        }
      }else{
        image_Arrays = '../../../assets/images/notfound.png';
      }
       const albums = {
         src: image_Arrays,
      
     };
        this._albums_othr.push(albums);
   
    },
    err=>{
      console.log(err)
    })
  }
   console.log(this._albums_othr)
}

_albums_Pres=[];
callImage_Prescription(i:number,id:any,displayDataPrescriptions){
  this.indexid=i;
  this.displayDataPrescriptions=displayDataPrescriptions;
  this.loading = true
  let image_Arrays = '';
  this._albums_Pres.length=0;
  for(let j=0;j<displayDataPrescriptions.length;j++){
    this.service.getImageById(displayDataPrescriptions[j].id).subscribe((res: any)=>{
      console.log(res)
      this.loading = false
      this.healthRecordsLoaded = true
      if(res.response.documentType!='application/pdf'){
        if(res.response.imageContents!= null){
          image_Arrays = 'data:image/jpg;base64,' + res.response.imageContents;
        }  
        else{
          image_Arrays = '../../../assets/images/notfound.png';
        }
      }else{
        image_Arrays = '../../../assets/images/notfound.png';
      }
      
     const albums = {
         src: image_Arrays,
            };
        this._albums_Pres.push(albums);
    
    },
    err=>{
      console.log(err)
    })
  }
   console.log(this._albums_Pres)
}

_albums_lab=[];
 callImage(i:number,id:any,displayData){
    this.indexid=i;
    this.displayData=displayData;
    this.loading = true
    let image_Arrays = '';
    this._albums_lab.length=0;
    for(let j=0;j<displayData.length;j++){
      this.service.getImageById(displayData[j].id).subscribe((res: any)=>{
        console.log(res)
        this.loading = false
        this.healthRecordsLoaded = true
        if(res.response.documentType!='application/pdf'){
          if(res.response.imageContents!= null){
            image_Arrays = 'data:image/jpg;base64,' + res.response.imageContents;
            }  
          else{
            image_Arrays = '../../../assets/images/notfound.png';
          }
        }else{
          image_Arrays = '../../../assets/images/notfound.png';
        }
       const albums = {
           src: image_Arrays,
         };
      this._albums_lab.push(albums);
       },
      err=>{
        console.log(err)
      })
    }
       console.log(this._albums_lab)
  }


 /* callImage(id:any){
    this.loading = true
    this.service.getImageById(id).subscribe((res: any)=>{
      console.log(res)
      this.loading = false
      this.healthRecordsLoaded = true
      // this.imageArray=''
      if(res.response.documentType!='application/pdf')
        this.imageArray='data:image/png;base64,' + res.response.imageContents

      else
        this.imageArray='data:application/pdf;base64,' + res.response.imageContents

      // console.log(this.imageArray)
    },
    err=>{
      console.log(err)
    })
  }*/



  returnToPatientList(){
    this.router.navigate(["doctor/patientList"]);
  }
  goBackToPreviousPage(): void {
    window.history.back();
  }
  _albums = [];
  openLight_box(src:any): any {
    // open lightbox
    this._albums.length=0;
      const album = {
      src: src,
     };
   this._albums.push(album);
       // alert(this._albums);
    console.log(this._albums);
    this._lightbox.open(this._albums, 0);
  }
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
    this._albums.length=0;
  }
  closePrescription():void{
    this.enableModal = false;
  }
}
