import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { BasicAuthInterceptorService } from './basic-auth-interceptor.service';
import { AddAppointmentScheduleComponent } from './components/add-appointment-schedule/add-appointment-schedule.component';
import { DoctorConsultationHistoryComponent } from './components/doctor-consultation-history/doctor-consultation-history.component';
import { DoctorDashboardComponent } from './components/doctor-dashboard/doctor-dashboard.component';
import { CalendarViewComponent } from './components/calendar-view/calendar-view.component';
import { VideoCallComponent } from './components/video-call/video-call.component';
import { AdminLoginComponent } from './components/adminlogin/adminlogin.component';
import { JitsiVideoComponent } from './components/jitsi-video/jitsi-video.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { HealthRecordsComponent } from './components/health-records/health-records.component';
import { PatientListComponent } from './components/patient-list/patient-list.component';
import { ViewAppointmentsComponent } from './components/view-appointments/view-appointments.component';
import { PrescriptionPageComponent } from './components/prescription-page/prescription-page.component';
import { DoctorInvoiceComponent } from './components/doctor-invoice/doctor-invoice.component';


const routes: Routes = [
  { path: "", redirectTo: "user/login", pathMatch: "full" },
  { path: "user/login", component: LoginComponent },
  { path: "doctor/dashboard", component: DoctorDashboardComponent },
  { path: "doctor/addAppointment", component: AddAppointmentScheduleComponent },
  { path: "doctor/patientList", component: PatientListComponent },
  { path: "doctor/healthRecords", component: HealthRecordsComponent },
  { path: "doctor/viewAppointments", component: ViewAppointmentsComponent },
  { path: "doctor/transactions", component: TransactionsComponent },
  { path: "doctor/calendar", component: CalendarViewComponent },
  { path: "doctor/videoCall", component: VideoCallComponent },
  { path: "doctor/prescription", component: PrescriptionPageComponent },
  { path: "doctor/jitsiMeet", component: JitsiVideoComponent },
  { path: "doctor/invoice", component: DoctorInvoiceComponent },
  { path: "doctor/consultationHistory", component: DoctorConsultationHistoryComponent },
  { path: 'healthworker', loadChildren: () => import('./healthworker/healthworker.module').then(m => m.HealthworkerModule) },
  { path: 'privacy_policy', component:PrivacypolicyComponent},
  {
    path: 'appadmin/login',
    component: AdminLoginComponent,
  },
  { path: 'adminlanding',  loadChildren: () => import('../app/admin-module/admin-module.module').then((m) => m.AdminModuleModule),}

];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes,{ onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
