import { DatePipe } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { MatDialog, MatSnackBar } from '@angular/material'
import { DoctorService } from '../../doctor.service'
import { UserService } from '../../user.service'
import { Router } from "@angular/router";
import { NgxImageCompressService } from 'ngx-image-compress';
import { ConfirmationModelComponent } from '../confirmation-model/confirmation-model.component'
import { HealthworkerService } from "../../healthworker/healthworker.service";

@Component({
  selector: 'app-upcoming-appointments',
  templateUrl: './upcoming-appointments.component.html',
  styleUrls: ['./upcoming-appointments.component.css']
})
export class UpcomingAppointmentsComponent implements OnInit {

  prescriptionsToShow: any = [];
  enableModal : boolean = false;
  appointments: any = []
  public pagingStartIndex: number
  public pagingEndIndex: number
  public pageSize: number
  public numberOfPages: number
  public pageNum: number[]
  public currentPage: number
  public displayData: any
  public newStatus: string
  public selectedDate: any
  public today = new Date()
  public patId: string;
  prescriptionImage: any;
  base64Prescription: any;
  formData: any = new FormData();
  prescriptionPictureUpload: boolean = false
  prescriptionCaptured: boolean = false
  @Input() upcomingAppointments: any
  snackbarOptions: any = {
    duration: 3000,
    verticalPosition: 'bottom'
  }
  loading: boolean = false;
  invoice : any;
  enableInvoiceModal : boolean;

  constructor(
    private service: UserService,
    private dialog: MatDialog,
    private router: Router,
    private doctorService: DoctorService,
    private snackbar: MatSnackBar,
    private datePipe: DatePipe,
    private imageCompress: NgxImageCompressService,
    private healthworkerService: HealthworkerService) {
    this.pagingStartIndex = 0
    this.displayData = []
    this.pageNum = []
    this.pageSize = 5 //default page size
    this.currentPage = 1
  }

  ngOnInit() {
    this.enableModal = false;
    this.service.userDetails = JSON.parse(localStorage.getItem('userDetails'))
    this.loading = true
    this.doctorService.getUpcomingDoctorAppointmentsList(this.service.userDetails.docId).subscribe((res: any) => {
      console.log(res)
      this.loading = false
      res.response.forEach(element => {
        let d = this.datePipe.transform(this.today, 'MMM dd y')
        let p = this.datePipe.transform(element.appDate, 'MMM dd y')
        if ((d < p))
          this.appointments.push(element)
      })
      console.log(this.appointments)
      this.displayPage(1)
    },
      err => {
        console.log(err)
      })

  }

  openDashboard(consultation: any) {
    localStorage.setItem('consultation', JSON.stringify(consultation))
    this.service.patient = consultation;
    this.router.navigate(["doctor/healthRecords"]);
    window["ReactNativeWebView"] &&
      window["ReactNativeWebView"].postMessage(
        JSON.stringify({  "backButtonDisabled": false,"change": true,"path":"openPatientProfile" })
      );
    console.log("notifyReact");
    // let dialogRef = this.dialog.open(ConfirmationModelComponent)
    // dialogRef.componentInstance.consultationDetail = consultation
  }


  public displayPage(pageNumber: number) {

    this.pagingStartIndex = this.pageSize * (pageNumber - 1)
    this.pagingEndIndex = Number.parseInt(this.pagingStartIndex.toString()) + Number.parseInt(this.pageSize.toString()) - 1
    this.displayData = []
    this.pageNum = []
    let mod: number = this.appointments.length % this.pageSize
    if (mod > 0) this.numberOfPages = Math.floor(this.appointments.length / this.pageSize) + 1
    else this.numberOfPages = (this.appointments.length / this.pageSize)

    console.log("Numberofpages" + this.numberOfPages)

    for (let i = 0 ;i < this.numberOfPages ;i++) {
      this.pageNum.push(i + 1)
    }

    //update the data to be displayed based on above changes
    for (let i = this.pagingStartIndex ;i <= this.pagingEndIndex ;i++) {
      if (this.appointments[i] != null) {
        this.displayData.push(this.appointments[i])
      }
    }

    //update current page
    this.currentPage = pageNumber
  }

  confirmAppointment(consultationId: any, index: any){
    console.log(consultationId)
    this.doctorService.updateAppointmentStatus(consultationId, 'CONFIRMED').subscribe((res: any)=>{
        console.log(res)
        if(res.responseCode=="200"){
          this.snackbar.open(res.message, '', this.snackbarOptions)
          this.displayData[index].status = 'CONFIRMED'
        }
    })
  }

  rejectAppointment(consultationId: any, index: any){
    this.loading = true;
    this.doctorService.updateAppointmentStatus(consultationId, 'CANCELLED').subscribe((res: any)=>{
        this.loading = false;
        if(res.responseCode=="200"){
          this.displayData[index].status = 'CANCELLED';
        }
        this.snackbar.open(res.message, '', this.snackbarOptions)
    },
    error=>{
      this.loading = false;
    })
  }

  cancelAppontment(event: any) {
    this.rejectAppointment(event.consultationId, event.index);
  }

  prescriptionUpload(e, index, consultationId,patId){
    this.patId = patId;
    this.prescriptionImage = e.target.files[0]
    let size = e.target.files[0].size / (1024 * 1024)
    console.log(this.prescriptionImage)
    if(this.prescriptionImage!=null){
      if(size>15)
          this.snackbar.open("File size can't be more than 15 mb!!", '',{
            duration: 3000,
            verticalPosition: 'bottom'
          })
      else{
        this.convertToBase64(this.prescriptionImage,consultationId, index)
      }
    }
  }

  convertToBase64(file: File, consultationId: any, index: number){
    const reader: FileReader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = ()=> {
      this.base64Prescription = reader.result
      this.submitNewPrescriptionAndClinicalNotes(consultationId, index)
    }
  }


  submitNewPrescriptionAndClinicalNotes(consultationId: any, index: number) {
    this.loading = true
    this.formData = new FormData()
    this.base64Prescription.replace(/^data:image\/[a-z]+;base64,/, "")
    console.log('Size before compression' + this.prescriptionImage.size)
    this.imageCompress.compressFile(this.base64Prescription, 50, 50).then(
      result => {
        console.warn('Size in bytes is now:', this.imageCompress.byteCount(result))
        let p = result.replace(/^data:image\/[a-z]+;base64,/, "")
        const imageBlob = this.dataURItoBlob(p)
        const imageFile = new File([imageBlob], this.prescriptionImage.name, { type: 'image/jpg' })
        this.formData.append('clinicalNotePic', imageFile)
        this.callPrescriptionUploadApi(consultationId, index)
    })

  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }


  callPrescriptionUploadApi(consultationId: any, index: number){
    this.doctorService.addPrescriptionAndClinicalNotes(this.formData, this.patId, consultationId).subscribe((res: any) => {
      console.log(res)
      this.loading = false
      if (res.responseCode == "201") {
        //this.oldPrescriptionList.push(res.response)
        // this.getOldPrescriptionList()
        this.prescriptionPictureUpload = true
        this.prescriptionCaptured = false
        this.formData = new FormData()
        this.snackbar.open("Prescription Submitted!!!", '', {
          duration: 3000,
          verticalPosition: 'bottom'
        })
        //this.displayData.remove(index);
        this.getUpcomingAppointments()
      }
    },
      err => {
        this.loading = false
        console.log(err)
      })
  }

  getUpcomingAppointments(){
    this.loading = true;
    this.doctorService
      .getUpcomingDoctorAppointmentsList(this.service.userDetails.docId)
      .subscribe(
        (res: any) => {
          console.log(res);
          this.loading = false;
          res.response.forEach((element) => {
            let d = this.datePipe.transform(this.today, "MMM dd y");
            let p = this.datePipe.transform(element.appDate, "MMM dd y");
            if (
              d == p &&

              element.status != "CANCELLED"
            )
              this.appointments.push(element);

          });
          this.displayPage(1);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  showPrescription(data:any){
    this.service.patient = data;
    this.router.navigate(['/doctor/prescription']);
  }


  showPrescription1(data : any):void{
    console.log(data);
    this.loading = true;
    // this.doctorService.getClinicalNotes(data).subscribe((res: any) => {
    //   console.log(res)
    //   this.loading = false
    //   if (res.responseCode == "200") {
    //     this.prescriptionsToShow = res.response;
    //   }else{
    //     this.prescriptionsToShow = null;
    //   }
    //   this.loading = false;
    //   this.enableModal = true;
    // },
    //   err => {
    //     this.loading = false
    //     console.log(err)
    //   })

      this.doctorService.getOldPrescriptions(data.patId).subscribe((res: any) => {
        console.log(res);
        this.loading = false
        if (res.responseCode == "200" || res.responseCode == "201") {
          this.prescriptionsToShow = res.response;
        }
        this.loading = false;
        this.enableModal = true;
      },
        err => {
          this.loading = false
          console.log(err)
        })
  }
  closePrescription():void{
    this.enableModal = false;
  }

  showCancelAppoinmentButton(status): boolean {
    return status == 'CONFIRMED';
  }

  getInvoice(consultationId: string){
    this.loading = true;
    this.healthworkerService.getInvoice(consultationId).subscribe((res: any)=>{
      console.log(res)
      this.invoice = res.response
      this.loading = false
      this.enableInvoiceModal = true;
    },
      err => {
        this.loading = false
      });
  }

  closeInvoice(){
    this.enableInvoiceModal = false;
  }
}
