import { Component, OnInit } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DoctorService } from '../../doctor.service';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {

  patientList: any = [];
  patientName: string = "";
  dataURL: any[] = [];
  loading: boolean = false;
  base64Images: any = [];
  showBackBtn: boolean = false;
  isEnabled = false;
  constructor(
    public service: UserService,
    public doctorService: DoctorService,
    private router: Router,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    public translate: TranslateService
  ) {
    this.router.events.subscribe((navChange) => {
      if(navChange instanceof NavigationEnd) {
        this.showBackBtn = (navChange.urlAfterRedirects !== "/doctor/dashboard");
      }
    }); 
  }

  ngOnInit() {
    this.isEnabled = true;
    this.service.userDetails = JSON.parse(localStorage.getItem('userDetails'))

    if (this.isEnabled) {
      window["ReactNativeWebView"] &&
        window["ReactNativeWebView"].postMessage(
          JSON.stringify({ "backButtonDisabled":false,"change": true, "path":"Mobile patient list 2" })
        );
      console.log("notifyReact");
    }

    this.service.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (this.service.userDetails == null)
      this.router.navigate(["healthworker/login"]);
    this.getPatientList();
  }

  getPatientList() {
    this.loading = true;
    this.doctorService
      .getPatientList(this.service.userDetails.docId)
      .subscribe(
        (res: any) => {
          console.log(res);
          this.loading = false;
          this.patientList = res.response;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  openPatientProfile(patient: any) {
    this.service.patient = patient;
    this.router.navigate(["doctor/healthRecords"]);
    window["ReactNativeWebView"] &&
      window["ReactNativeWebView"].postMessage(
        JSON.stringify({  "backButtonDisabled": false,"change": true,"path":"doctorOpenPatientProfile" })
      );
    console.log("notifyReact");
  }

  viewAppointments(patient: any) {
    this.service.patient = patient;
    this.router.navigate(["doctor/viewAppointments"]);
    window["ReactNativeWebView"] &&
      window["ReactNativeWebView"].postMessage(
        JSON.stringify({ "backButtonDisabled": false,"change": true, "path":"doctorViewAppointments" })
      );
    console.log("notifyReact");
  }

  ngDestroy() {
    this.isEnabled = false;
  }
  goBackToPreviousPage(): void {
    window.history.back();
  }

}
