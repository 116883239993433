import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppadminService {
  headers: any;
  base_url = "";
  authtoken = "";
  constructor(private http: HttpClient) {
    let token= "YWRtaW46YWRtaW4xMjM=";
    this.headers= new HttpHeaders({
      "Authorization": "Bearer " + token 
   })

   }

  AppadminAuthenticate(username: String, password: String) {
    return this.http.get('/admin/adminlogin',
      { headers: { 'X-Requested-With': 'XMLHttpRequest', authorization: this.createBasicAuthToken(username, password) } })
  }
  createBasicAuthToken(username: String, password: String) {
    return 'Basic ' + window.btoa(username + ":" + password)
  }
  createhealthworker(formdata){
    console.log("in service");
    //let serializedForm = JSON.stringify(formdata);
    return this.http.post(this.base_url+'/admin/healthworkers', formdata,
    //{ headers: { 'X-Requested-With': 'XMLHttpRequest', authorization: this.createBasicAuthToken("admin", "admin123") } })
    { headers: { authorization: this.getAuthToken() } })
  
    //YWRtaW46YWRtaW4xMjM=
  }
  updatehealthworker(formdata){
    console.log("in service");
    let serializedForm = JSON.stringify(formdata);
    return this.http.put(this.base_url+'/admin/healthworkers/'+formdata.hwId+'/profile', serializedForm,
    //{ headers: { 'X-Requested-With': 'XMLHttpRequest', authorization: this.createBasicAuthToken("admin", "admin123") } })
    { headers: { 'X-Requested-With': 'XMLHttpRequest','Content-Type':'application/json', authorization: this.getAuthToken() } })
  
    //YWRtaW46YWRtaW4xMjM=
  }
  createdoctor(formdata){
    console.log("in service");
    //let serializedForm = JSON.stringify(formdata);
    return this.http.post(this.base_url+'/admin/doctors', formdata,
    { headers: {authorization: this.getAuthToken() } })
  
    //YWRtaW46YWRtaW4xMjM=
  }
  updatedoctor(formdata){
    console.log("in doctor update service");
    let serializedForm = JSON.stringify(formdata);
    return this.http.put(this.base_url+'/admin/doctors/'+formdata.docId+'/profile', serializedForm,
    //{ headers: { 'X-Requested-With': 'XMLHttpRequest', authorization: this.createBasicAuthToken("admin", "admin123") } })
    { headers: { 'X-Requested-With': 'XMLHttpRequest','Content-Type':'application/json', authorization: this.getAuthToken() } })
  
  }
  getDoctorImage(docId){
    console.log("in doctor get im age service");
   // let serializedForm = JSON.stringify(docId);
    return this.http.get(this.base_url+'/api/users/profilePic/'+docId, 
    //{ headers: { 'X-Requested-With': 'XMLHttpRequest', authorization: this.createBasicAuthToken("admin", "admin123") } })
    { headers: { authorization: this.getAuthToken() },responseType: 'blob' })
  }
  getUsersList(roles){
    console.log("in doctor list"); // /admin/users/doctors
    return this.http.get(this.base_url+'/admin/users/'+roles,
    { headers: { authorization: this.getAuthToken() }})
  }
  getpatientList(){
    console.log("in patient list");
    return this.http.get(this.base_url+'/admin/users/listofpatients',
    { headers: { authorization: this.getAuthToken() }})
  }
  getDocCounts(){
    console.log("in doc count");
    return this.http.get(this.base_url+'/admin/doctors/count',
    { headers: { authorization: this.getAuthToken() }})
  }
  getHWCounts(){
    console.log("in HW count");
    return this.http.get(this.base_url+'/admin/healthworkers/count',
    { headers: { authorization: this.getAuthToken() }})
  }
  getPTCounts(){
    console.log("in HW count");
    return this.http.get(this.base_url+'/admin/patients/count',
    { headers: { authorization: this.getAuthToken() }})
  }
  getAppointmentCounts(){
    console.log("in HW count");
    return this.http.get(this.base_url+'/admin/totalappointments/count',
    { headers: { authorization: this.getAuthToken() }})
  }
  getappointmets(){
    console.log("in appointment list");
    return this.http.get(this.base_url+'/admin/getAdminAppointments',
    { headers: { authorization: this.getAuthToken() }})
  }
  getDoctorDetails(id): Observable<any>{
      return this.http.get(this.base_url+'/admin/doctors/'+ id,
      { headers: { authorization: this.getAuthToken() } })
    }

  updateDoctorProfile(docId, formData): Observable<any>{
    return this.http.put(this.base_url+'/admin/doctors/'+docId+'/editprofile', formData,
    { headers: { authorization: this.getAuthToken() } })
  } 

  deleteHealthWorker(workerId): Observable<any>{
    return this.http.delete(this.base_url+'admin/healthworkers/'+workerId,
    { headers: { authorization: this.getAuthToken() } })
  }

  deleteDctr(docId): Observable<any>{
    return this.http.delete(this.base_url+'admin/doctors/'+docId,
    { headers: { authorization: this.getAuthToken() } })
  }

  // updateHWProfile(HWId, reqdata): Observable<any>{
  //   return this.http.put(this.base_url+'/admin/healthworkers/'+HWId+'/profile', reqdata,
  //   { headers: { 'X-Requested-With': 'XMLHttpRequest','Content-Type':'application/json', authorization: this.createBasicAuthToken("admin", "admin123") } })
  // } 

  getHWDetails(HWId): Observable<any>{
    return this.http.get(this.base_url+'/admin/healthworkers/'+ HWId,
    { headers: { authorization: this.getAuthToken() } })
  }

 updateHWProfile(hWId,formData): Observable<any>{
    return this.http.put(this.base_url+'/admin/rmp/'+hWId+'/profile', formData,
    { headers: { authorization: this.getAuthToken() } })
  }
  gethwIdProof(hwIdProof): Observable<any>{
    //return this.http.get(this.base_url+'/api/users/hwIdProof/'+ hwIdProof,
    return this.http.get(this.base_url+'/api/users/hwIdProof/'+ hwIdProof,
    { headers: { authorization: this.getAuthToken() },responseType: 'blob'  })
  }

  gethwCert(hwCert): Observable<any>{
    return this.http.get(this.base_url+'/api/users/hwCert/'+ hwCert,
    { headers: { authorization: this.getAuthToken() },responseType: 'blob' })
  }
  getdcCert(dctrCert): Observable<any>{
    return this.http.get(this.base_url+'/api/users/regnCert/'+ dctrCert,
    { headers: { authorization: this.getAuthToken() },responseType: 'blob' })
  } 

  getTransactions(): Observable<any>{
    return this.http.get(this.base_url+'/admin/getAdminAppointments',
    { headers: { authorization: this.getAuthToken() }})
  } 

  updatePaymentStatus(req): Observable<any>{
      return this.http.post(this.base_url+'/admin/paymentstatus', req,
      { headers: {authorization: this.getAuthToken() } })
    }

   
    getTotalRevenue(): Observable<any>{
      return this.http.get(this.base_url+'/admin/totalRevenue',
      { headers: { authorization: this.getAuthToken() }})
    }

    getWeeklyRevenue(): Observable<any>{
      return this.http.get(this.base_url+'/admin/weeklyRevenue',
      { headers: { authorization: this.getAuthToken() }})
    }
  
    getDailyRevenue(): Observable<any>{
      return this.http.get(this.base_url+'/admin/dailyRevenue',
      { headers: { authorization: this.getAuthToken() }})
    }
    getMonthlyRevenue(): Observable<any>{
      return this.http.get(this.base_url+'/admin/monthlyRevenue',
      { headers: { authorization: this.getAuthToken() }})
    }
    
    getAppointmentCount(){
      return this.http.get(this.base_url+'/admin/totalappointments/count',
      { headers: { authorization: this.getAuthToken() }})
    }

    uploadDoctorPrfilepic(docId, formData): Observable<any>{
      return this.http.put(this.base_url+'admin/doctors/profilepic/'+ docId, formData,
      { headers: { authorization: this.getAuthToken() } })
    }

    uploadDoctorIdproof(docId, formData): Observable<any>{
      return this.http.put(this.base_url+'admin/doctors/docregncert/'+ docId, formData,
      { headers: { authorization: this.getAuthToken() } })
    }

    uploadDoctorHospitalLogo(docId, formData): Observable<any> {
      return this.http.put(this.base_url + 'admin/doctors/hospitallogo/' + docId, formData,
        { headers: { authorization: this.getAuthToken() } })
    }

    uploadRmpPrfilepic(rmpId, formdata): Observable<any>{
      return this.http.put(this.base_url+'admin/rmp/profilepic/'+ rmpId, formdata,
      { headers: { authorization: this.getAuthToken() } })
    }

      uploadRmpIdproof(rmpId, formdata): Observable<any>{
      return this.http.put(this.base_url+'admin/rmp/rmpidproof/'+ rmpId, formdata,
      { headers: { authorization: this.getAuthToken() } })
    }

    uploadRmpCertificate(rmpId, formdata): Observable<any>{
      return this.http.put(this.base_url+'admin/rmp/rmpregncert/'+ rmpId, formdata,
      { headers: { authorization: this.getAuthToken() } })
    }

    getDoctorsTransactins(){
      return this.http.get(this.base_url+'/admin/getdoctortransactions',
      { headers: { authorization: this.getAuthToken() }})
    }

    getRppTransactins(){
      return this.http.get(this.base_url+'/admin/getrmptransactions',
      { headers: { authorization: this.getAuthToken() }})
    }

    // /admin/getalltransactions

    getAllTransactins(){
      return this.http.get(this.base_url+'/admin/getalltransactions',
      { headers: { authorization: this.getAuthToken() }})
    }

    // DoctorTransactionStatus(body){
    //   return this.http.put(this.base_url+'/admin/changedoctortransactionstatus/'+ body.consultationId + '/' + body.status,
    //   { headers: { authorization: this.getAuthToken() }})
    // }

    // RmpTransactionStatus(body){
    //   return this.http.put(this.base_url+'/admin/changermptransactionstatus/'+ body.consultationId + '/' + body.status,
    //   { headers: { authorization: this.getAuthToken() }})
    // }

    // /admin//updatePaymentStatus/{consultationId}/{status}/{person}

   TransactionStatus(body){
      return this.http.post(this.base_url+'/admin/updatePaymentStatus/'+ body.consultationId + '/' + body.status + '/' + body.person, body.reason,
      { headers: { authorization: this.getAuthToken() }})
    }

    getAuthToken():string {
      return sessionStorage.getItem("basicauth");
    }

    getList(path:string,pageNumber:number,length?: number){
      pageNumber=pageNumber-1;
      let url=this.base_url+path+`${pageNumber}`
      console.log("Base Url in Get List Function", url);
      if(length){

        url=url+"/"+`${length}`;
      }
      return this.http.get(url,
      { headers: { authorization: this.getAuthToken() }})
    }

    getTransactionList(type:string,pageNumber:number,length?: number){
      let path;
      if(type=='all'){
        path="getalltransactions_list";
      }else if (type=='rmp'){
        path="getrmptransactions_list";
      }else if(type =='doctor'){
        path="getdoctortransactions_list";
      }

      pageNumber=pageNumber-1;
      let url=this.base_url+'/admin/'+path+"/"+`${pageNumber}`;
      if(length){
        url=url+"/"+`${length}`;
      }
      return this.http.get(url,
      { headers: { authorization: this.getAuthToken() }})
    }
}
