import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthAuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class AdminRestApiService {


  formData: {};
  host = ""
  username: string;
  password: string;
  hospitalId: string;
  postData = {};

  isUserLoggedIn() {
    throw new Error("Method not implemented.");
  }
  constructor(private http: HttpClient, private router: Router, private authservice: AuthAuthenticationService) { }

  public get(hospitalId: string) {
    if (this.authservice.isUserLoggedIn() != null) {
      return this.http.get(this.host + "/admin/getHospital?hospitalId=" + hospitalId)
    }
    else {
      this.router.navigate(["Admin/login"])
    }
  }

  public addhospital(hospitalId: string, hospitalAddress: string, hospitalName: string, zipCode: number, department: string[]) {
    this.postData = {
      "department": department,
      "hospitalId": hospitalId,
      "hospitalAddress": hospitalAddress,
      "hospitalName": hospitalName,
      "zipCode": zipCode
    };
    return this.http.post(this.host + "/admin/addHospital", this.postData)
  }

  public removeHospital(HospitalId: string) {
    if (this.authservice.isUserLoggedIn() != null) {
      return this.http.delete(this.host + "/admin/removeHospital?hospitalId=" + HospitalId);
    }
  }

  public getHospitalsList() {
    return this.http.get(this.host + "/admin/getAllHospital")
  }

  public update(hospitalId: string, hospitalName: string, hospitalAddress: string, zipCode: Number, department: string[]) {

    this.postData = {
      "hospitalId": hospitalId,
      "hospitalName": hospitalName,
      "hospitalAddress": hospitalAddress,
      "zipCode": zipCode,
      "department": department
    };
    return this.http.put(this.host + "/admin/updateHospitalDetails", this.postData);
  }

  public addPaymentCredentials(id: any, data: any){
    return this.http.post(this.host + '/admin/addPaymentCredentials/' + id, data)
  }

  public getPaymentCredentials(id: any){
    return this.http.get(this.host + '/admin/getPaymentCredentials/' + id)
  }
}
